// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PrintInvoice_PrintInvoiceWrapper__gCQ7g {\n  padding: 5%;\n}\n.PrintInvoice_PrintInvoiceWrapper__gCQ7g .PrintInvoice_prodName__PlZZZ {\n  margin-bottom: 0;\n  font-size: 13px;\n  color: #67666b;\n}\n.PrintInvoice_PrintInvoiceWrapper__gCQ7g .PrintInvoice_prodPrice__DV-gI {\n  margin-bottom: 0;\n  font-size: 11px;\n  color: #d02130;\n}", "",{"version":3,"sources":["webpack://./src/components/PrintInvoice/PrintInvoice.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;AACI;EACI,gBAAA;EACA,eAAA;EACA,cAAA;AACR;AAEI;EACI,gBAAA;EACA,eAAA;EACA,cAAA;AAAR","sourcesContent":[".PrintInvoiceWrapper {\n    padding: 5%;\n\n    .prodName {\n        margin-bottom: 0;\n        font-size: 13px;\n        color: #67666b;\n    }\n\n    .prodPrice {\n        margin-bottom: 0;\n        font-size: 11px;\n        color: #d02130;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PrintInvoiceWrapper": "PrintInvoice_PrintInvoiceWrapper__gCQ7g",
	"prodName": "PrintInvoice_prodName__PlZZZ",
	"prodPrice": "PrintInvoice_prodPrice__DV-gI"
};
export default ___CSS_LOADER_EXPORT___;
