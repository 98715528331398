// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddBanner_AddBannerWrapper__XVYkZ .AddBanner_heading__r8ORJ {\n  color: #ae674c;\n}\n.AddBanner_AddBannerWrapper__XVYkZ .AddBanner_textEnd__BFrcN {\n  margin-bottom: 0;\n}\n.AddBanner_AddBannerWrapper__XVYkZ .AddBanner_addBannerContainer__pqeJg {\n  padding: 30px;\n}\n.AddBanner_AddBannerWrapper__XVYkZ .AddBanner_addBannerContainer__pqeJg .AddBanner_content__kehr6 {\n  background-color: #fff;\n  padding: 30px;\n  width: 100%;\n}\n.AddBanner_AddBannerWrapper__XVYkZ .AddBanner_addBannerContainer__pqeJg .AddBanner_content__kehr6 p {\n  margin-bottom: 3px;\n}\n.AddBanner_AddBannerWrapper__XVYkZ .AddBanner_addBannerContainer__pqeJg .AddBanner_content__kehr6 input {\n  height: 35px;\n}", "",{"version":3,"sources":["webpack://./src/Pages/AddBanner/AddBanner.module.scss"],"names":[],"mappings":"AACI;EACI,cAAA;AAAR;AAGI;EAEI,gBAAA;AAFR;AAII;EACI,aAAA;AAFR;AAIQ;EACI,sBAAA;EACA,aAAA;EACA,WAAA;AAFZ;AAIY;EACI,kBAAA;AAFhB;AAKY;EACI,YAAA;AAHhB","sourcesContent":[".AddBannerWrapper {\n    .heading {\n        color: #ae674c;\n    }\n\n    .textEnd {\n        // text-align: end;\n        margin-bottom: 0;\n    }\n    .addBannerContainer {\n        padding: 30px;\n\n        .content {\n            background-color: #fff;\n            padding: 30px;\n            width: 100%;\n\n            p {\n                margin-bottom: 3px;\n            }\n\n            input {\n                height: 35px;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AddBannerWrapper": "AddBanner_AddBannerWrapper__XVYkZ",
	"heading": "AddBanner_heading__r8ORJ",
	"textEnd": "AddBanner_textEnd__BFrcN",
	"addBannerContainer": "AddBanner_addBannerContainer__pqeJg",
	"content": "AddBanner_content__kehr6"
};
export default ___CSS_LOADER_EXPORT___;
