import { CloseOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import {
    Breadcrumb,
    Button,
    Col,
    Input,
    message,
    Row,
    Space,
    Switch,
    // TreeSelect,
    Upload,
} from "antd";
import Topbar from "../../components/TopBar";
import { Formik } from "formik";
import { useState } from "react";
import { token } from "../../utils";
import * as Yup from "yup";

import styles from "./AddCategory.module.scss";
import { useNavigate } from "react-router-dom";
import PrivateAxios from "../../services/axiosService";
import TagsInputComponent from "../../components/TagsInput/TagsInput";

function AddCategory() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    // const [categories, setCategories] = useState("");
    const { TextArea } = Input;

    const CategorySchema = Yup.object().shape({
        name: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Please enter the category name"),
        isActive: Yup.boolean(),
        description: Yup.string()
            .required("Please enter category description")
            .min(2, "Too Short!"),
        // parentId: Yup.string().required("Please select a category option"),
        slug: Yup.string().required("Please enter category slug"),
        tags: Yup.array()
            .min(1, "Please add a slug")
            .required("Please add a slug"),
        menuImage: Yup.array()
            .min(1, "Choose an image")
            .required("Choose an image"),
        webBanner: Yup.array()
            .min(1, "Choose an image")
            .required("Choose an image"),
        mobileBanner: Yup.array()
            .min(1, "Choose an image")
            .required("Choose an image"),
    });

    //FETCH ALL CATEGORIES
    // const fetchCategoriesHandler = useCallback(() => {
    //     let config = {
    //         headers: {
    //             "x-auth-token": token(),
    //         },
    //     };
    //     PrivateAxios.get("/categories?filter=all", config)
    //         .then(function (response) {
    //             setLoading(false);
    //             setCategories(response?.data);
    //         })
    //         .catch(function (error) {
    //             setLoading(false);
    //             message.error(error?.response?.data?.message);
    //         });
    // }, []);

    // useEffect(() => {
    //     fetchCategoriesHandler();
    // }, [fetchCategoriesHandler]);

    const LeftComponent = () => (
        <div>
            <Breadcrumb separator='>'>
                <Breadcrumb.Item href='/products'>Catalog</Breadcrumb.Item>
                <Breadcrumb.Item href='/category'>Category</Breadcrumb.Item>
                <Breadcrumb.Item> Add Category</Breadcrumb.Item>
            </Breadcrumb>
            <h3 className={styles.heading}>Add Category</h3>
        </div>
    );

    const RightComponent = () => <div />;

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    // const parentCategories = () => [
    //     {
    //         value: "root",
    //         title: "Root",
    //         children:
    //             categories?.length > 0 &&
    //             categories?.map((category) => ({
    //                 value: category._id,
    //                 title: category.name,
    //             })),
    //     },
    // ];
    return (
        <div className={styles.AddCategoryWrapper}>
            <Topbar
                LeftComponent={LeftComponent}
                RightComponent={RightComponent}
            />

            <div className={styles.content}>
                <Formik
                    initialValues={{
                        name: "",
                        visibility: true,
                        description: "",
                        // parentId: "root",
                        menuImage: [],
                        webBanner: [],
                        mobileBanner: [],
                        tags: [],
                        slug: "",
                        isSpecial: false,
                    }}
                    validationSchema={CategorySchema}
                    onSubmit={(values) => {
                        setLoading(true);
                        const data = new FormData();
                        data.append("name", values.name);
                        data.append("isActive", values.visibility);
                        data.append("description", values.description);
                        data.append("menu", values.menuImage[0].originFileObj);
                        data.append("web", values.webBanner[0]?.originFileObj);
                        data.append(
                            "mobile",
                            values.mobileBanner[0]?.originFileObj
                        );
                        // values.parentId !== "root" &&
                        //     data.append("parent", values.parentId);
                        data.append("slug", values.slug);
                        data.append("keywords", values.tags);
                        data.append("isSpecial", values.isSpecial);

                        const config = {
                            headers: {
                                "x-auth-token": token(),
                                "Content-Type": "multipart/form-data",
                            },
                        };
                        PrivateAxios.post("/categories", data, config)
                            .then(function (response) {
                                setLoading(false);
                                message.success(response?.data?.message);
                                navigate("/categories");
                            })
                            .catch(function (error) {
                                setLoading(false);
                                message.error(error?.response?.data?.message);
                            });
                    }}
                >
                    {({
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                        handleReset,
                        setFieldValue,
                        values,
                    }) => (
                        <div className={styles.AddCategoryContainer}>
                            <Col className='p-3'>
                                <Row
                                    align='middle'
                                    justify='center'
                                    className='mb-4'
                                >
                                    <Col span={4}>
                                        <p className='text-right mb-0'>
                                            Category Name{" "}
                                            <span className='text-danger'>
                                                *
                                            </span>
                                        </p>
                                    </Col>
                                    <Col span={8}>
                                        <Input
                                            placeholder='Category Name'
                                            onChange={handleChange("name")}
                                            onBlur={handleBlur("name")}
                                            value={values.name}
                                            className='mt-3'
                                        />
                                        <pre className='text-danger'>
                                            {errors.name && touched.name ? (
                                                <div>{errors.name}</div>
                                            ) : null}
                                        </pre>
                                    </Col>
                                </Row>
                                <Row
                                    align='middle'
                                    justify='center'
                                    className='mb-4'
                                >
                                    <Col span={4}>
                                        <p className='text-right mb-0'>
                                            Visibility{" "}
                                            <span className='text-danger'>
                                                *
                                            </span>
                                        </p>
                                    </Col>
                                    <Col span={8}>
                                        <Switch
                                            checkedChildren='True'
                                            unCheckedChildren='False'
                                            onChange={(value) => {
                                                setFieldValue(
                                                    "visibility",
                                                    value
                                                );
                                            }}
                                            checked={values.visibility}
                                        />
                                    </Col>
                                </Row>
                                {/* <Row
                                    align='middle'
                                    justify='center'
                                    className='mb-4'
                                >
                                    <Col span={4}>
                                        <p className='text-right mb-0'>
                                            Parent Category
                                            <span className='text-danger'>
                                                *
                                            </span>
                                        </p>
                                    </Col>
                                    <Col span={8}>
                                        <TreeSelect
                                            treeLine={true}
                                            style={{ width: "100%" }}
                                            treeData={parentCategories()}
                                            onSelect={(e) =>
                                                setFieldValue("parentId", e)
                                            }
                                            value={values.parentId}
                                        />
                                    </Col>
                                </Row> */}
                                <Row
                                    align='middle'
                                    justify='center'
                                    className='mb-4'
                                >
                                    <Col span={4}>
                                        <p className='text-right mb-0'>
                                            IsSpecial
                                        </p>
                                    </Col>
                                    <Col span={8}>
                                        <Switch
                                            onChange={(value) => {
                                                setFieldValue(
                                                    "isSpecial",
                                                    value
                                                );
                                            }}
                                            checked={values.isSpecial}
                                        />
                                    </Col>
                                </Row>
                                <Row
                                    align='top'
                                    justify='center'
                                    className='mb-4'
                                >
                                    <Col span={4}>
                                        <p className='text-right mb-0'>
                                            Description
                                            <span className='text-danger'>
                                                *
                                            </span>
                                        </p>
                                    </Col>
                                    <Col span={8}>
                                        <TextArea
                                            rows={4}
                                            placeholder='Description'
                                            onChange={handleChange(
                                                "description"
                                            )}
                                            onBlur={handleBlur("description")}
                                            value={values.description}
                                        />

                                        <pre className='text-danger'>
                                            {errors.description &&
                                            touched.description ? (
                                                <div>{errors.description}</div>
                                            ) : null}
                                        </pre>
                                    </Col>
                                </Row>

                                <Row
                                    align='middle'
                                    justify='center'
                                    className='mb-4'
                                >
                                    <Col span={4}>
                                        <p className='text-right mb-0'>
                                            Images{" "}
                                            <span className='text-danger'>
                                                *
                                            </span>
                                        </p>
                                    </Col>
                                    <Col span={8}>
                                        <Row justify='space-between'>
                                            <Col span={8}>
                                                <p className='mb-1'>
                                                    Menu Image
                                                </p>
                                                <Upload
                                                    listType='picture-card'
                                                    className='avatar-uploader'
                                                    showUploadList={true}
                                                    onChange={(e) => {
                                                        handleBlur("menuImage");
                                                        setFieldValue(
                                                            "menuImage",
                                                            e.fileList
                                                        );
                                                    }}
                                                    beforeUpload={() => false}
                                                    accept='image/*'
                                                    fileList={values.menuImage}
                                                    maxCount={1}
                                                    onRemove={() =>
                                                        setFieldValue(
                                                            "menuImage",
                                                            []
                                                        )
                                                    }
                                                >
                                                    {uploadButton}
                                                </Upload>
                                                {touched.menuImage &&
                                                errors.menuImage ? (
                                                    <pre className='text-danger'>
                                                        {errors.menuImage}
                                                    </pre>
                                                ) : null}
                                            </Col>
                                            <Col span={8}>
                                                <p className='mb-1'>
                                                    Web Banner
                                                </p>
                                                <Upload
                                                    listType='picture-card'
                                                    className='avatar-uploader'
                                                    showUploadList={true}
                                                    onChange={(e) => {
                                                        handleBlur("webBanner");
                                                        setFieldValue(
                                                            "webBanner",
                                                            e.fileList
                                                        );
                                                    }}
                                                    beforeUpload={() => false}
                                                    accept='image/*'
                                                    fileList={values.webBanner}
                                                    maxCount={1}
                                                    onRemove={() =>
                                                        setFieldValue(
                                                            "webBanner",
                                                            []
                                                        )
                                                    }
                                                >
                                                    {uploadButton}
                                                </Upload>
                                                {touched.webBanner &&
                                                errors.webBanner ? (
                                                    <pre className='text-danger'>
                                                        {errors.webBanner}
                                                    </pre>
                                                ) : null}
                                            </Col>
                                            <Col span={8}>
                                                <p className='mb-1'>
                                                    Mobile Banner
                                                </p>
                                                <Upload
                                                    listType='picture-card'
                                                    className='avatar-uploader'
                                                    showUploadList={true}
                                                    onChange={(e) => {
                                                        handleBlur(
                                                            "mobileBanner"
                                                        );
                                                        setFieldValue(
                                                            "mobileBanner",
                                                            e.fileList
                                                        );
                                                    }}
                                                    beforeUpload={() => false}
                                                    accept='image/*'
                                                    fileList={
                                                        values.mobileBanner
                                                    }
                                                    maxCount={1}
                                                    onRemove={() =>
                                                        setFieldValue(
                                                            "mobileBanner",
                                                            []
                                                        )
                                                    }
                                                >
                                                    {uploadButton}
                                                </Upload>
                                                {touched.mobileBanner &&
                                                errors.mobileBanner ? (
                                                    <pre className='text-danger'>
                                                        {errors.mobileBanner}
                                                    </pre>
                                                ) : null}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row
                                    align='middle'
                                    justify='center'
                                    className='mb-1'
                                >
                                    <Col span={4}>
                                        <p className='text-right mb-0'>
                                            Meta Keywords
                                            <span className='text-danger'>
                                                *
                                            </span>
                                        </p>
                                    </Col>
                                    <Col span={8}>
                                        <TagsInputComponent
                                            tagData={values.tags}
                                            setTagData={setFieldValue}
                                        />
                                        <pre className='text-danger'>
                                            {errors.tags && touched.tags ? (
                                                <div>{errors.tags}</div>
                                            ) : null}
                                        </pre>
                                    </Col>
                                </Row>
                                <Row
                                    align='middle'
                                    justify='center'
                                    className='mb-4'
                                >
                                    <Col span={4}>
                                        <p className='text-right mb-0'>
                                            Slug{" "}
                                            <span className='text-danger'>
                                                *
                                            </span>
                                        </p>
                                    </Col>
                                    <Col span={8}>
                                        <Input
                                            placeholder='Friendly Slug'
                                            onChange={handleChange("slug")}
                                            onBlur={handleBlur("slug")}
                                            value={values.slug}
                                            className='mt-3'
                                        />
                                        <pre className='text-danger'>
                                            {errors.slug && touched.slug ? (
                                                <div>{errors.slug}</div>
                                            ) : null}
                                        </pre>
                                    </Col>
                                </Row>
                            </Col>
                            <Row align='middle' justify='center'>
                                <Space>
                                    <Button
                                        type='default'
                                        icon={<CloseOutlined />}
                                        size='large'
                                        onClick={handleReset}
                                        className='d-flex align-items-center'
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        type='primary'
                                        icon={<SaveOutlined />}
                                        size='large'
                                        onClick={handleSubmit}
                                        className='d-flex align-items-center'
                                        loading={loading}
                                    >
                                        Save
                                    </Button>
                                </Space>
                            </Row>
                        </div>
                    )}
                </Formik>{" "}
            </div>
        </div>
    );
}

export default AddCategory;
