// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Orders_OrdersWrapper__zImT2 thead[class*=ant-table-thead] th {\n  background-color: #fff !important;\n}\n.Orders_OrdersWrapper__zImT2 .Orders_heading__832-5 {\n  color: #ae674c;\n}\n.Orders_OrdersWrapper__zImT2 .Orders_ordersContainer__ZASno {\n  padding: 30px;\n  padding-top: 0;\n}", "",{"version":3,"sources":["webpack://./src/Pages/Orders/Orders.module.scss"],"names":[],"mappings":"AACI;EACI,iCAAA;AAAR;AAGI;EACI,cAAA;AADR;AAII;EACI,aAAA;EACA,cAAA;AAFR","sourcesContent":[".OrdersWrapper {\n    thead[class*=\"ant-table-thead\"] th {\n        background-color: #fff !important;\n    }\n\n    .heading {\n        color: #ae674c;\n    }\n\n    .ordersContainer {\n        padding: 30px;\n        padding-top: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"OrdersWrapper": "Orders_OrdersWrapper__zImT2",
	"heading": "Orders_heading__832-5",
	"ordersContainer": "Orders_ordersContainer__ZASno"
};
export default ___CSS_LOADER_EXPORT___;
