import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import {
    Breadcrumb,
    Button,
    Col,
    Input,
    message,
    Row,
    Select,
    Switch,
    Upload,
} from "antd";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import Topbar from "../../components/TopBar";
import PrivateAxios from "../../services/axiosService";
import { getUrlExtension, token } from "../../utils";
import { IMAGE_URL } from "../../utils/constants";
import styles from "./EditProduct.module.scss";

const EditProduct = () => {
    const { productId } = useParams();
    const { TextArea } = Input;
    const { Option } = Select;
    const navigate = useNavigate();
    const { state } = useLocation();
    const { data } = state;
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);

    //YUP PRODUCT SCHEMA
    const variationSchema = {
        name: Yup.string()
            .min(2, "Too Short!")
            .max(250, "Too Long!")
            .required("Please enter the product name"),
        description: Yup.string()
            .required("Please enter detailed description")
            .min(1, "Too Short!"),
        category: Yup.string().required("Please choose category"),
        slug: Yup.string().required("Please enter a slug"),
        primaryImage: Yup.array()
            .min(1, "Please choose an image")
            .required("Please choose an image"),
        isVisible: Yup.bool(),
    };

    const withoutVariationSchema = {
        price: Yup.number()
            .typeError("Please enter a valid price")
            .min(1, "Price should be greate than or equal to 1")
            .required("Enter price of product "),
        // galleryImages: Yup.array()
        //     .min(1, "Please choose an image")
        //     .required("Please choose an image"),
    };

    const ProductSchema = Yup.object().shape(
        data?.productType === "variant"
            ? { ...variationSchema }
            : { ...variationSchema, ...withoutVariationSchema }
    );

    const handleImageUpload = async (formData, mainImage, gallery) => {
        if (data?.productType !== "variant") {
            await Promise.all(
                gallery.map(async (item) => {
                    await imageAppendHandler(item, formData, "gallery");
                })
            );
            // for (const item of gallery) {
            //     await imageAppendHandler(item.thumbUrl, formData, "gallery");
            // }
        }
        await imageAppendHandler(mainImage[0], formData, "mainImage");
        return true;
    };

    //APPEND IMAGES TO FORM-DATA
    const imageAppendHandler = async (image, data, name) => {
        if (image?.originFileObj) {
            await data.append(name, image?.originFileObj);
        } else {
            const url = image.thumbUrl;
            const imgExt = getUrlExtension(url);
            const fileName = `myFile.${imgExt}`;
            let file;
            await fetch(url).then(async (response) => {
                const blob = await response.blob();
                file = new File([blob], fileName, {
                    type: blob.type,
                });
                data.append(name, file);
            });
            return file;
        }
    };

    //FETCH ALL CATEGORIES
    const fetchCategoryHandler = () => {
        const config = {
            headers: {
                "x-auth-token": token(),
            },
        };
        PrivateAxios.get("/categories?filter=all", config)
            .then(function (response) {
                setCategories(response?.data);
            })
            .catch(function (error) {
                message.error(error?.response?.data?.message);
            });
    };

    useEffect(() => {
        fetchCategoryHandler();
    }, []);

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const LeftComponent = () => (
        <div>
            <Breadcrumb separator='>'>
                <Breadcrumb.Item href='/products'>Catalog</Breadcrumb.Item>
                <Breadcrumb.Item href='/products'>Products</Breadcrumb.Item>
            </Breadcrumb>
            <h3 className={styles.heading}> Edit Product</h3>
        </div>
    );

    const RightComponent = () => <div />;
    return (
        <div className={styles.EditProductWrapper}>
            <Topbar
                LeftComponent={LeftComponent}
                RightComponent={RightComponent}
            />
            <div className={styles.editProductContainer}>
                <div className={styles.content}>
                    <Formik
                        initialValues={{
                            name: data?.name,
                            description: data?.description,
                            price: parseInt(data?.price),
                            category: data?.category?._id,
                            slug: data?.slug,
                            isVisible: Boolean(data?.isActive),
                            primaryImage: [
                                {
                                    thumbUrl: `${IMAGE_URL}${data?.images?.mainImage}`,
                                },
                            ],
                            galleryImages:
                                data?.images?.gallery?.length > 0
                                    ? data?.images?.gallery.map((image) => ({
                                          thumbUrl: IMAGE_URL + image,
                                      }))
                                    : [],
                        }}
                        validationSchema={ProductSchema}
                        onSubmit={async (values) => {
                            setLoading(true);
                            let formData = new FormData();
                            await handleImageUpload(
                                formData,
                                values.primaryImage,
                                values.galleryImages
                            );
                            formData.append("name", values.name);
                            if (data?.productType !== "variant") {
                                formData.append("price", values.price);
                            }
                            formData.append("description", values.description);
                            formData.append("category", values.category);
                            formData.append("isActive", values.isVisible);
                            formData.append("slug", values.slug);

                            const config = {
                                headers: {
                                    "x-auth-token": token(),
                                    "Content-Type": "multipart/form-data",
                                },
                            };
                            let query;
                            if (data?.productType === "variant") {
                                query = "productType=variant";
                            } else {
                                query = "productType=simple";
                            }

                            PrivateAxios.patch(
                                `/products/${productId}?${query}`,
                                formData,
                                config
                            )
                                .then(function (response) {
                                    setLoading(false);
                                    message.success(response?.data?.message);
                                    navigate("/products");
                                })
                                .catch(function (error) {
                                    message.error(
                                        error?.response?.data?.message
                                    );
                                    setLoading(false);
                                });
                        }}
                    >
                        {({
                            errors,
                            touched,
                            handleChange,
                            handleSubmit,
                            handleBlur,
                            setFieldValue,
                            values,
                        }) => (
                            <>
                                <div className='mb-3'>
                                    <p>
                                        Product Name
                                        <span className='text-danger'>*</span>
                                    </p>
                                    <Input
                                        placeholder='Product name'
                                        onChange={handleChange("name")}
                                        onBlur={handleBlur("name")}
                                        value={values.name}
                                    />
                                    {errors.name && touched.name ? (
                                        <pre className='text-danger'>
                                            {errors.name}
                                        </pre>
                                    ) : null}
                                </div>
                                <div className='mb-3'>
                                    <p>
                                        Description
                                        <span className='text-danger'>*</span>
                                    </p>
                                    <TextArea
                                        rows={6}
                                        placeholder='Description'
                                        onChange={handleChange("description")}
                                        onBlur={handleBlur("description")}
                                        value={values.description}
                                    />
                                    {errors.description &&
                                    touched.description ? (
                                        <pre className='text-danger'>
                                            {errors.description}
                                        </pre>
                                    ) : null}
                                </div>
                                {data?.productType !== "variant" && (
                                    <div className='mb-3'>
                                        <p>
                                            Price
                                            <span className='text-danger'>
                                                *
                                            </span>
                                        </p>
                                        <Input
                                            addonBefore='AED'
                                            placeholder='Price'
                                            onChange={handleChange("price")}
                                            onBlur={handleBlur("price")}
                                            value={values.price}
                                            type='number'
                                            min={1}
                                        />
                                        {errors.price && touched.price ? (
                                            <pre className='text-danger'>
                                                {errors.price}
                                            </pre>
                                        ) : null}
                                    </div>
                                )}

                                <div className='mb-3'>
                                    <p>
                                        Category
                                        <span className='text-danger'>*</span>
                                    </p>
                                    <Select
                                        placeholder='Select a category'
                                        className='w-100'
                                        value={values.category}
                                        onChange={handleChange("category")}
                                        onBlur={handleBlur("category")}
                                    >
                                        <Option value=''>Select</Option>
                                        {categories?.map((item) => {
                                            return (
                                                <Option
                                                    key={item._id}
                                                    value={item._id}
                                                >
                                                    {item.name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                    {errors.category && touched.category ? (
                                        <pre className='text-danger'>
                                            {errors.category}
                                        </pre>
                                    ) : null}
                                </div>

                                <Row className='mb-3 mt-4'>
                                    <span className='me-3'>Is Visible?</span>
                                    <Switch
                                        checked={values.isVisible}
                                        onChange={(value) =>
                                            setFieldValue("isVisible", value)
                                        }
                                    />
                                </Row>

                                <div className='pt-3'>
                                    <p>Slug</p>
                                    <Input
                                        defaultValue={data.slug}
                                        placeholder='Enter Slug'
                                        onChange={handleChange("slug")}
                                        onBlur={handleBlur("slug")}
                                        value={values.slug}
                                    />

                                    {errors.slug && touched.slug ? (
                                        <pre className='text-danger'>
                                            {errors.slug}
                                        </pre>
                                    ) : null}
                                </div>
                                <span className='d-flex align-items-center'>
                                    <h5 className='text-bold mt-3'>Images</h5>
                                </span>
                                <Row>
                                    <Col>
                                        <p>
                                            Main Image{" "}
                                            <span className='text-danger'>
                                                *
                                            </span>
                                        </p>
                                        <Upload
                                            listType='picture-card'
                                            className='avatar-uploader'
                                            showUploadList={true}
                                            onChange={(e) => {
                                                handleBlur("primaryImage");
                                                setFieldValue(
                                                    "primaryImage",
                                                    e.fileList
                                                );
                                            }}
                                            beforeUpload={() => false}
                                            accept='image/*'
                                            fileList={values.primaryImage}
                                            maxCount={1}
                                            onRemove={() =>
                                                setFieldValue(
                                                    "primaryImage",
                                                    []
                                                )
                                            }
                                        >
                                            {uploadButton}
                                        </Upload>
                                        {touched.primaryImage &&
                                        errors.primaryImage ? (
                                            <pre className='text-danger'>
                                                {errors.primaryImage}
                                            </pre>
                                        ) : null}
                                    </Col>
                                    {data?.productType !== "variant" && (
                                        <Col>
                                            <div className='pl-3'>
                                                <p>Gallery Image (Max 4)</p>
                                                <Upload
                                                    listType='picture-card'
                                                    className='avatar-uploader'
                                                    showUploadList={true}
                                                    onChange={(e) => {
                                                        handleBlur(
                                                            "galleryImages"
                                                        );
                                                        setFieldValue(
                                                            "galleryImages",
                                                            e.fileList
                                                        );
                                                    }}
                                                    beforeUpload={() => false}
                                                    accept='image/*'
                                                    fileList={
                                                        values.galleryImages
                                                    }
                                                    maxCount={4}
                                                    onRemove={({ uid }) =>
                                                        setFieldValue(
                                                            "galleryImages",
                                                            values.galleryImages.filter(
                                                                (image) =>
                                                                    image.uid !==
                                                                    uid
                                                            )
                                                        )
                                                    }
                                                >
                                                    {uploadButton}
                                                </Upload>
                                                {touched.galleryImages &&
                                                errors.galleryImages ? (
                                                    <pre className='text-danger'>
                                                        {errors.galleryImages}
                                                    </pre>
                                                ) : null}
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                                {/* <pre>* First Image will be main (thumbnail) product image </pre> */}
                                <div className='w-100 d-flex align-items-center justify-content-center'>
                                    <Button
                                        onClick={handleSubmit}
                                        type='primary'
                                        icon={<SaveOutlined />}
                                        size='large'
                                        loading={loading}
                                        className='d-flex align-items-center'
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default EditProduct;
