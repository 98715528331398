import React from "react";
import { DeleteFilled, EditFilled, PlusOutlined } from "@ant-design/icons";
import {
    Breadcrumb,
    Button,
    message,
    Popconfirm,
    Row,
    Space,
    Table,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Topbar from "../../components/TopBar";
import PrivateAxios from "../../services/axiosService";
import { token } from "../../utils";
import styles from "./Emirates.module.scss";

const Emirates = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [emirates, setEmirates] = useState([]);

    //FETCH ALL PRODUCTS
    const fetchEmirates = useCallback(() => {
        setIsLoading(true);
        let config = {
            headers: {
                "x-auth-token": token(),
            },
        };
        PrivateAxios.get("/emirates", config)
            .then(function (response) {
                setEmirates(response?.data);
                setIsLoading(false);
            })
            .catch(function (error) {
                setIsLoading(false);
                message.error(error?.response?.data?.message);
            });
    }, []);

    //DELETE A BOX
    const deleteEmirate = (id) => {
        setIsLoading(true);
        let config = {
            headers: {
                "x-auth-token": token(),
            },
        };

        PrivateAxios.delete(`/delivery-charges/${id}`, config)
            .then(function (response) {
                setEmirates((prevState) =>
                    prevState.filter((box) => box._id !== id)
                );
                setIsLoading(false);
                message.success(response?.data?.message);
            })
            .catch(function (error) {
                setIsLoading(false);
                message.error(error?.response?.data?.message);
            });
    };

    useEffect(() => {
        fetchEmirates();
    }, [fetchEmirates]);

    //DATASOURCE FOR PRODUCT TABLE
    const data =
        emirates?.length > 0 &&
        emirates.map((item, i) => ({
            key: item?._id,
            id: i + 1,
            emirate: item?.name,
            _id: item?._id,
            ...item,
        }));

    //COLUMN DETAILS FOR PRODUCTS TABLE
    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "_id",
            render: (text) => <p className='text-center'>{text}</p>,
            // width: 150,
            align: "center",
        },
        {
            title: "Emirate Name",
            dataIndex: "emirate",
            key: "emirate",
            // width: 100,
            align: "center",
        },
        {
            title: "Edit",
            key: "edit",
            align: "center",
            width: 150,
            render: (item) => (
                <Button
                    shape='circle'
                    type='text'
                    disabled
                    icon={<EditFilled />}
                    onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/edit-emirate/${item._id}`, {
                            state: { data: item },
                        });
                    }}
                />
            ),
        },
        {
            title: "Delete",
            key: "delete",
            align: "center",
            width: 150,
            render: (item) => (
                <Popconfirm
                    title='Are you sure to delete this emirate?'
                    onConfirm={(e) => {
                        e.stopPropagation();
                        deleteEmirate(item?._id);
                    }}
                    onCancel={(e) => {
                        e.stopPropagation();
                    }}
                    okText='Yes'
                    cancelText='No'
                >
                    <Button
                        shape='circle'
                        type='text'
                        disabled
                        icon={<DeleteFilled />}
                        className='text-danger'
                    />
                </Popconfirm>
            ),
        },
    ];

    const LeftComponent = () => (
        <div>
            <Breadcrumb separator='>'>
                <Breadcrumb.Item>Settings</Breadcrumb.Item>
                <Breadcrumb.Item>Emirates</Breadcrumb.Item>
            </Breadcrumb>
            <h3 className={styles.heading}>Emirates</h3>
        </div>
    );

    const RightComponent = () => (
        <Row>
            <Space>
                <Button
                    type='primary'
                    disabled
                    icon={<PlusOutlined />}
                    size='large'
                    className={styles.btn}
                    onClick={() => navigate("/add-emirate")}
                >
                    Add Emirate
                </Button>
            </Space>
        </Row>
    );

    return (
        <div className={styles.EmiratesWrapper}>
            <Topbar
                LeftComponent={LeftComponent}
                RightComponent={RightComponent}
            />

            <div className={styles.emiratesContainer}>
                <Table
                    columns={columns}
                    dataSource={data}
                    loading={isLoading}
                />
            </div>
        </div>
    );
};

export default Emirates;
