export { default as AddBanner } from "./AddBanner";
export { default as AddCategory } from "./AddCategory";
export { default as AddDeliveryCharge } from "./AddDeliveryCharge";
export { default as AddProduct } from "./AddProduct";
export { default as Categories } from "./Categories";
export { default as DeliveryCharge } from "./DeliveryCharge";
export { default as EditProduct } from "./EditProduct";
export { default as Emirates } from "./Emirates";
export { default as HomeManagement } from "./HomeManagement";
export { default as Login } from "./Login";
export { default as NotFound } from "./NotFound";
export { default as OrderDetail } from "./OrderDetail";
export { default as Orders } from "./Orders";
export { default as Products } from "./Products";
export { default as ProductDetail } from "./ProductDetail";
