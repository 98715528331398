import "./App.scss";
import { Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import PublicRoute from "./components/PublicRoute";
import PrivateRoute from "./components/PrivateRoute";
import {
    AddBanner,
    AddCategory,
    // AddDeliveryCharge,
    AddProduct,
    Categories,
    DeliveryCharge,
    EditProduct,
    Emirates,
    HomeManagement,
    Login,
    NotFound,
    OrderDetail,
    Orders,
    ProductDetail,
    Products,
} from "./Pages";
import Wrapper from "./components/Layout";

const App = () => {
    return (
        <>
            <ScrollToTop />
            <Routes>
                <Route element={<PublicRoute />}>
                    <Route path='/login' element={<Login />} />
                </Route>
                <Route element={<PrivateRoute />}>
                    <Route element={<Wrapper />}>
                        <Route path='/' element={<Orders />} />
                        <Route
                            path='/order/:orderId'
                            element={<OrderDetail />}
                        />
                        <Route path='/products' element={<Products />} />
                        <Route
                            path='/products/add-product'
                            element={<AddProduct />}
                        />
                        <Route
                            path='/products/product-detail/:productId'
                            element={<ProductDetail />}
                        />
                        <Route
                            path='/products/edit-product/:productId'
                            element={<EditProduct />}
                        />
                        <Route path='/categories' element={<Categories />} />
                        <Route path='/add-category' element={<AddCategory />} />
                        <Route
                            path='/home-management'
                            element={<HomeManagement />}
                        />
                        <Route path='/add-banner' element={<AddBanner />} />
                        <Route
                            path='/edit-banner/:bannerId'
                            element={<AddBanner />}
                        />
                        <Route path='/emirates' element={<Emirates />} />
                        <Route
                            path='/delivery-charge'
                            element={<DeliveryCharge />}
                        />
                        {/* <Route
                            path='/add-delivery-charge'
                            element={<AddDeliveryCharge />}
                        /> */}
                    </Route>
                </Route>
                <Route path='*' element={<NotFound />} />
            </Routes>
        </>
    );
};

export default App;
