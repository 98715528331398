import { Modal } from "antd";
import axios from "axios";
import { BASE_URL } from "../utils/constants";

const PrivateAxios = axios.create({
    baseURL: `${BASE_URL}`,
});

PrivateAxios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (
            error.response.status === 401
            // || error.response.status === 400
        ) {
            localStorage.clear();
            window.dispatchEvent(new Event("storage"));
            Modal.destroyAll();
            Modal.error({
                title: "Session expired!",
                content: "Please login again.",
                onOk: () => {},
            });
        } else {
            return Promise.reject(error);
        }
    }
);
export default PrivateAxios;
