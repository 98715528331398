// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Layout_LayoutWrapper__KgZ66 .Layout_sidebar__T6D3N {\n  border-right: 1px solid #ececec;\n}\n.Layout_LayoutWrapper__KgZ66 .Layout_sidebar__T6D3N .Layout_logoWrapper__q5QQA {\n  max-height: 80px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.Layout_LayoutWrapper__KgZ66 .Layout_sidebar__T6D3N .Layout_logoWrapper__q5QQA .Layout_logo__E\\+6TJ {\n  width: 100%;\n  object-fit: contain;\n  padding: 10px;\n}\n.Layout_LayoutWrapper__KgZ66 .Layout_header__TyB9T {\n  background-color: #ffffff;\n  border-bottom: 1px solid #ececec;\n}\n.Layout_LayoutWrapper__KgZ66 .Layout_settings__XcIg\\+ {\n  height: 28px;\n  width: 28px;\n}", "",{"version":3,"sources":["webpack://./src/components/Layout/Layout.module.scss"],"names":[],"mappings":"AACI;EACI,+BAAA;AAAR;AAEQ;EACI,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAZ;AACY;EACI,WAAA;EACA,mBAAA;EACA,aAAA;AAChB;AAII;EACI,yBAAA;EACA,gCAAA;AAFR;AAKI;EACI,YAAA;EACA,WAAA;AAHR","sourcesContent":[".LayoutWrapper {\n    .sidebar {\n        border-right: 1px solid #ececec;\n        // background-color: #ffffff;\n        .logoWrapper {\n            max-height: 80px;\n            display: flex;\n            align-items: center;\n            justify-content: center;\n            .logo {\n                width: 100%;\n                object-fit: contain;\n                padding: 10px;\n            }\n        }\n    }\n\n    .header {\n        background-color: #ffffff;\n        border-bottom: 1px solid #ececec;\n    }\n\n    .settings {\n        height: 28px;\n        width: 28px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LayoutWrapper": "Layout_LayoutWrapper__KgZ66",
	"sidebar": "Layout_sidebar__T6D3N",
	"logoWrapper": "Layout_logoWrapper__q5QQA",
	"logo": "Layout_logo__E+6TJ",
	"header": "Layout_header__TyB9T",
	"settings": "Layout_settings__XcIg+"
};
export default ___CSS_LOADER_EXPORT___;
