import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    DeleteFilled,
    DownOutlined,
    EditFilled,
    PlusOutlined,
} from "@ant-design/icons";
import {
    Breadcrumb,
    Button,
    Col,
    Dropdown,
    Input,
    message,
    Popconfirm,
    Radio,
    Row,
    Space,
    Table,
    Tag,
} from "antd";
import { useCallback, useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import Topbar from "../../components/TopBar";
import { ACTION_TYPES, productReducer } from "../../reducer/productReducer";
import PrivateAxios from "../../services/axiosService";
import { token } from "../../utils";
import { IMAGE_URL } from "../../utils/constants";
import styles from "./Products.module.scss";

const { Search } = Input;

const Products = () => {
    const navigate = useNavigate();
    const [productState, productDispatch] = useReducer(productReducer);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [searchData, setSearchData] = useState("");
    const [pSize, setpSize] = useState(10);

    //FETCH ALL PRODUCTS
    const fetchProductsHandler = useCallback(
        (pageNo, pageSize, categoryId, searchQuery) => {
            productDispatch({ type: ACTION_TYPES.FETCH_PRODUCTS_START });
            const pageNumber = pageNo ? pageNo : 1;
            const size = pageSize ? pageSize : 10;
            const category = categoryId
                ? `&category=${categoryId}`
                : selectedCategory
                ? `&category=${selectedCategory}`
                : "";
            const search = searchQuery ? `&q=${searchQuery}` : "";
            let config = {
                headers: {
                    "x-auth-token": token(),
                },
            };

            PrivateAxios.get(
                `/products?page=${pageNumber}&size=${size}${category}${search}`,
                config
            )
                .then(function (response) {
                    productDispatch({
                        type: ACTION_TYPES.FETCH_PRODUCTS_SUCCESS,
                        payload: {
                            products: response?.data?.data,
                            totalProducts: response?.data?.totalItems,
                            currentPage: response?.data?.currentPage,
                        },
                    });
                })
                .catch(function (error) {
                    productDispatch({
                        type: ACTION_TYPES.FETCH_PRODUCTS_ERROR,
                    });
                    message.error(error?.response?.data?.message);
                });
        },
        [selectedCategory]
    );

    //FETCH ALL CATEGORIES
    const fetchCategoryHandler = () => {
        productDispatch({ type: ACTION_TYPES.FETCH_CATEGORIES_START });
        let config = {
            headers: {
                "x-auth-token": token(),
            },
        };
        PrivateAxios.get("/categories?filter=all", config)
            .then(function (response) {
                productDispatch({
                    type: ACTION_TYPES.FETCH_CATEGORIES_SUCCESS,
                    payload: { categories: response?.data },
                });
            })
            .catch(function (error) {
                productDispatch({ type: ACTION_TYPES.FETCH_CATEGORIES_ERROR });
                message.error(error?.response?.data?.message);
            });
    };

    //DELETE A PRODUCT FROM DB
    const deleteProductHandler = (id) => {
        productDispatch({ type: ACTION_TYPES.DELETE_PRODUCTS_START });
        let config = {
            headers: {
                "x-auth-token": token(),
            },
        };

        PrivateAxios.delete(`/products/${id}`, config)
            .then(function (response) {
                productDispatch({
                    type: ACTION_TYPES.DELETE_PRODUCTS_SUCCESS,
                    payload: { id: id },
                });
                response && message.success("Product Deleted Successfully");
            })
            .catch(function (error) {
                productDispatch({ type: ACTION_TYPES.DELETE_PRODUCTS_ERROR });
                message.error("Something went wrong!");
            });
    };

    useEffect(() => {
        fetchProductsHandler();
        fetchCategoryHandler();
    }, [fetchProductsHandler]);

    //COLUMN DETAILS FOR PRODUCTS TABLE
    const columns = [
        {
            title: "Product ID",
            dataIndex: "_id",
            key: "_id",
            render: (text) => <p className='text-center'>{text}</p>,

            align: "center",
        },
        {
            title: "Image",
            dataIndex: "image",
            key: "image",
            render: (src) => (
                <img
                    src={src}
                    alt='product-img'
                    className={styles.productImg}
                />
            ),

            align: "center",
        },
        {
            title: "Product Name",
            dataIndex: "name",
            key: "name",
            align: "center",
        },
        {
            title: "Price",
            key: "price",
            render: (data) => {
                return data?.productType === "variant" ? (
                    <Tag color='#f50'>Variant</Tag>
                ) : (
                    <span>{`AED ${Number(data?.price)?.toFixed(2)}`}</span>
                );
            },
            align: "center",
        },

        {
            title: "Visibility",
            key: "isActive",
            render: (data) => {
                return data.isActive ? (
                    <CheckCircleOutlined className='text-success fs-6' />
                ) : (
                    <CloseCircleOutlined className='text-danger fs-6' />
                );
            },
            align: "center",
        },
        {
            title: "Edit",
            key: "edit",
            align: "center",
            render: (item) => (
                <Button
                    type='text'
                    shape='circle'
                    icon={<EditFilled />}
                    onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/products/edit-product/${item._id}`, {
                            state: { data: item },
                        });
                    }}
                />
            ),
        },
        {
            title: "Delete",
            key: "delete",
            align: "center",
            render: (item) => (
                <Popconfirm
                    title='Are you sure to delete this product?'
                    onConfirm={(e) => {
                        e.stopPropagation();
                        deleteProductHandler(item?._id);
                    }}
                    onCancel={(e) => {
                        e.stopPropagation();
                    }}
                    okText='Yes'
                    cancelText='No'
                >
                    <Button
                        type='text'
                        shape='circle'
                        icon={<DeleteFilled />}
                        className='text-danger'
                        onClick={(e) => e.stopPropagation()}
                    />
                </Popconfirm>
            ),
        },
    ];

    //DATASOURCE FOR PRODUCT TABLE
    const data =
        productState?.products?.length > 0 &&
        productState?.products.map((item, i) => ({
            key: item?._id,
            id: i + 1,
            _id: item?._id,
            image: IMAGE_URL + item?.images?.mainImage,
            name: item?.name,
            price: Number(item?.price)?.toFixed(2),
            description: item?.description,
            isActive: item?.isActive,
            imageArr: item?.images?.gallery,
            ...item,
        }));

    //CATEGORY BUTTONS HANDLER
    const handleChangeCategory = (event) => {
        setSearchData("");
        setSelectedCategory(event.target.value);
        fetchProductsHandler("", "", event.target.value);
    };

    //CATEGORY MENU HANDLER
    const handleChangeCategoryMenu = (event) => {
        setSearchData("");
        setSelectedCategory(event.key);
        fetchProductsHandler("", "", event.key);
    };

    //MENU FOR EXCESS CATEGORIES
    const items = productState?.categories
        ?.slice(0, productState?.categories.length)
        .map((item) => ({ label: item.name, key: item._id }));

    const menuProps = {
        items,
        onClick: handleChangeCategoryMenu,
    };

    const LeftComponent = () => (
        <div>
            <Breadcrumb separator='>'>
                <Breadcrumb.Item>Catalog</Breadcrumb.Item>
                <Breadcrumb.Item>Products</Breadcrumb.Item>
            </Breadcrumb>
            <h3 className={styles.heading}>Products</h3>
        </div>
    );

    const RightComponent = () => (
        <Row>
            <Space>
                <Button
                    type='primary'
                    icon={<PlusOutlined />}
                    size='large'
                    className={styles.btn}
                    onClick={() => navigate("/products/add-product")}
                >
                    Add Products
                </Button>
            </Space>
        </Row>
    );

    return (
        <div className={styles.ProductWrapper}>
            <Topbar
                LeftComponent={LeftComponent}
                RightComponent={RightComponent}
            />
            <div className={styles.productContainer}>
                <Row
                    className='w-100 pt-3'
                    justify='space-between'
                    gutter={15}
                    align='middle'
                >
                    <Col span={10}>
                        <div className='d-flex'>
                            <Search
                                placeholder='Enter product name'
                                enterButton='Search'
                                size='large'
                                onSearch={(value) => {
                                    fetchProductsHandler("", "", "", value);
                                }}
                                onChange={(event) => {
                                    fetchProductsHandler(
                                        "",
                                        "",
                                        "",
                                        event.target.value
                                    );
                                    setSearchData(event.target.value);
                                }}
                                allowClear
                                value={searchData}
                            />
                            <Button
                                className='mx-3'
                                size='large'
                                onClick={() => {
                                    setSearchData(null);
                                    fetchProductsHandler();
                                }}
                            >
                                Reset
                            </Button>
                        </div>
                    </Col>

                    <Col
                        span={14}
                        className='d-flex justify-content-end align-items-center'
                    >
                        <Radio.Group
                            onChange={handleChangeCategory}
                            defaultValue=''
                            optionType='button'
                            buttonStyle='solid'
                            size='large'
                        >
                            {productState?.categories?.length > 0 &&
                                [
                                    ...[{ name: "All", _id: "" }],
                                    ...productState?.categories,
                                ]
                                    ?.slice(0, 1)
                                    ?.map((item) => (
                                        <Radio.Button
                                            onClick={handleChangeCategory}
                                            key={item?._id}
                                            value={item?._id}
                                        >
                                            {item?.name}
                                        </Radio.Button>
                                    ))}
                            {productState?.categories?.length > 1 && (
                                <Dropdown menu={menuProps}>
                                    <Button size='large'>
                                        More <DownOutlined />
                                    </Button>
                                </Dropdown>
                            )}
                        </Radio.Group>
                    </Col>
                </Row>

                <div className='my-4'>
                    <Table
                        columns={columns}
                        dataSource={data}
                        pagination={{
                            defaultCurrent: 1,
                            defaultPageSize: 10,
                            current: parseInt(productState?.currentPage) || 1,
                            pageSize: pSize,
                            showSizeChanger: true,
                            pageSizeOptions: ["10", "15", "20", "25"],
                            onChange: (pageNo, pageSize) => {
                                setpSize(pageSize);
                                if (searchData === "") {
                                    fetchProductsHandler(pageNo, pageSize);
                                } else {
                                    fetchProductsHandler(
                                        pageNo,
                                        pageSize,
                                        "",
                                        searchData
                                    );
                                }
                            },
                            total: productState?.totalProducts,
                        }}
                        loading={productState?.loading}
                        onRow={(record) => {
                            return {
                                onClick: () =>
                                    navigate(
                                        `/products/product-detail/${record._id}`,
                                        {
                                            state: { data: record },
                                        }
                                    ), // click row
                            };
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default Products;
