// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Emirates_EmiratesWrapper__HSBCa thead[class*=ant-table-thead] th {\n  background-color: #fff !important;\n}\n.Emirates_EmiratesWrapper__HSBCa .Emirates_heading__Z2Qy7 {\n  color: #ae674c;\n}\n.Emirates_EmiratesWrapper__HSBCa .Emirates_emiratesContainer__Xacou {\n  padding: 30px;\n}\n.Emirates_EmiratesWrapper__HSBCa .Emirates_emiratesContainer__Xacou .Emirates_btn__l96Mt {\n  border-radius: 5px;\n  display: flex;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/Pages/Emirates/Emirates.module.scss"],"names":[],"mappings":"AACI;EACI,iCAAA;AAAR;AAGI;EACI,cAAA;AADR;AAII;EACI,aAAA;AAFR;AAGQ;EACI,kBAAA;EACA,aAAA;EACA,mBAAA;AADZ","sourcesContent":[".EmiratesWrapper {\n    thead[class*=\"ant-table-thead\"] th {\n        background-color: #fff !important;\n    }\n\n    .heading {\n        color: #ae674c;\n    }\n\n    .emiratesContainer {\n        padding: 30px;\n        .btn {\n            border-radius: 5px;\n            display: flex;\n            align-items: center;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EmiratesWrapper": "Emirates_EmiratesWrapper__HSBCa",
	"heading": "Emirates_heading__Z2Qy7",
	"emiratesContainer": "Emirates_emiratesContainer__Xacou",
	"btn": "Emirates_btn__l96Mt"
};
export default ___CSS_LOADER_EXPORT___;
