export const ACTION_TYPES = {
    FETCH_START: "FETCH_START",
    FETCH_SUCCESS: "FETCH_SUCCESS",
    FETCH_ERROR: "FETCH_ERROR",
};

export const INITIAL_STATE = {
    loading: false,
    orders: [],
    totalOrdersCount: 0,
    currentPage: 1,
};

export const orderReducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_START:
            return {
                loading: true,
                orders: [],
                totalOrdersCount: 0,
                currentPage: 1,
            };

        case ACTION_TYPES.FETCH_SUCCESS:
            return {
                loading: false,
                orders: action.payload.orders,
                totalOrders: action.payload.totalOrders,
                currentPage: action.payload.currentPage,
            };

        case ACTION_TYPES.FETCH_ERROR:
            return {
                loading: false,
                orders: [],
                totalOrdersCount: 0,
                currentPage: 1,
            };

        default:
            return state;
    }
};
