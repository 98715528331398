// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DeliveryCharge_DeliveryChargeWrapper__HmKCv thead[class*=ant-table-thead] th {\n  background-color: #fff !important;\n}\n.DeliveryCharge_DeliveryChargeWrapper__HmKCv .DeliveryCharge_heading__FjMs6 {\n  color: #ae674c;\n}\n.DeliveryCharge_DeliveryChargeWrapper__HmKCv .DeliveryCharge_deliveryChargeContainer__eCLY9 {\n  padding: 30px;\n}\n.DeliveryCharge_DeliveryChargeWrapper__HmKCv .DeliveryCharge_deliveryChargeContainer__eCLY9 .DeliveryCharge_btn__wGyqQ {\n  border-radius: 5px;\n  display: flex;\n  align-items: center;\n}\n.DeliveryCharge_DeliveryChargeWrapper__HmKCv .DeliveryCharge_deliveryChargeContainer__eCLY9 .DeliveryCharge_btn2__PLmwl {\n  border-radius: 5px;\n  display: flex;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/Pages/DeliveryCharge/DeliveryCharge.module.scss"],"names":[],"mappings":"AACI;EACI,iCAAA;AAAR;AAGI;EACI,cAAA;AADR;AAGI;EACI,aAAA;AADR;AAEQ;EAGI,kBAAA;EACA,aAAA;EACA,mBAAA;AAFZ;AAKQ;EACI,kBAAA;EACA,aAAA;EACA,mBAAA;AAHZ","sourcesContent":[".DeliveryChargeWrapper {\n    thead[class*=\"ant-table-thead\"] th {\n        background-color: #fff !important;\n    }\n\n    .heading {\n        color: #ae674c;\n    }\n    .deliveryChargeContainer {\n        padding: 30px;\n        .btn {\n            // background-color: #d02130;\n            // border-color: #d02130;\n            border-radius: 5px;\n            display: flex;\n            align-items: center;\n        }\n\n        .btn2 {\n            border-radius: 5px;\n            display: flex;\n            align-items: center;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DeliveryChargeWrapper": "DeliveryCharge_DeliveryChargeWrapper__HmKCv",
	"heading": "DeliveryCharge_heading__FjMs6",
	"deliveryChargeContainer": "DeliveryCharge_deliveryChargeContainer__eCLY9",
	"btn": "DeliveryCharge_btn__wGyqQ",
	"btn2": "DeliveryCharge_btn2__PLmwl"
};
export default ___CSS_LOADER_EXPORT___;
