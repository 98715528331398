export const isLogin = () => {
  if (localStorage.getItem("GOLDEN_RIBBON_ADMIN_TOKEN")) {
    return true;
  }
  return false;
};

export const token = () => {
  return localStorage.getItem("GOLDEN_RIBBON_ADMIN_TOKEN");
};

export const getUrlExtension = (url) => {
  return url.split(/[#?]/)[0].split(".").pop().trim();
};

export const titleCase = (str) => {
  const splitStr = str?.toLowerCase()?.split(" ") || "";
  for (var i = 0; i < splitStr?.length; i++) {
    splitStr[i] =
      splitStr?.[i]?.charAt(0)?.toUpperCase() + splitStr[i]?.substring(1);
  }
  return splitStr?.join(" ");
};
