import React from "react";
// import { PlusOutlined } from "@ant-design/icons";
import {
    Breadcrumb,
    // Button,
    // message,
    // Modal,
    // Popconfirm,
    Row,
    Space,
    Table,
} from "antd";
// import { useState } from "react";
// import { useNavigate } from "react-router-dom";
import Topbar from "../../components/TopBar";
// import PrivateAxios from "../../services/axiosService";
// import { token } from "../../utils";
// import EditProduct from "../EditProduct";
import styles from "./DeliveryCharge.module.scss";

const DeliveryCharge = () => {
    // const navigate = useNavigate();
    // const [isLoading, setIsLoading] = useState(true);
    // const [deliveryCharge, setDeliveryCharge] = useState([]);
    // const [emirates, setEmirates] = useState([]);
    // const [isModalVisible, setIsModalVisible] = useState(null);

    // //FETCH ALL PRODUCTS
    // const fetchDeliveryCharges = useCallback(() => {
    //     setIsLoading(true);
    //     let config = {
    //         headers: {
    //             "x-auth-token": token(),
    //         },
    //     };
    //     PrivateAxios.get("/delivery-charge", config)
    //         .then(function (response) {
    //             setDeliveryCharge(response?.data);
    //             setIsLoading(false);
    //         })
    //         .catch(function (error) {
    //             setIsLoading(false);
    //             message.error(error?.response?.data?.message);
    //         });
    // }, []);

    // //FETCH ALL PRODUCTS
    // const fetchEmirates = useCallback(() => {
    //     setIsLoading(true);
    //     let config = {
    //         headers: {
    //             "x-auth-token": token(),
    //         },
    //     };
    //     PrivateAxios.get("/emirates", config)
    //         .then(function (response) {
    //             setEmirates(response?.data);
    //             setIsLoading(false);
    //         })
    //         .catch(function (error) {
    //             setIsLoading(false);
    //             message.error(error?.response?.data?.message);
    //         });
    // }, []);

    //DELETE A BOX
    // const deleteDeliveryCharge = (id) => {
    //     let config = {
    //         headers: {
    //             "x-auth-token": token(),
    //         },
    //     };

    //     PrivateAxios.delete(`/delivery-charges/${id}`, config)
    //         .then(function (response) {
    //             setDeliveryCharge((prevState) =>
    //                 prevState.filter((box) => box._id !== id)
    //             );
    //             setIsLoading(false);
    //             message.success(response?.data?.message);
    //         })
    //         .catch(function (error) {
    //             setIsLoading(false);
    //             message.error(error?.response?.data?.message);
    //         });
    // };

    // useEffect(() => {
    //     fetchDeliveryCharges();
    //     fetchEmirates();
    // }, [fetchDeliveryCharges, fetchEmirates]);

    const deliveryCharges = [
        {
            emirate: "Dubai",
            minimumOrder: 200,
        },
        {
            emirate: "Sharjah",
            minimumOrder: 200,
        },
        {
            emirate: "Ajman",
            minimumOrder: 200,
        },
        {
            emirate: "Umm Al Quwain",
            minimumOrder: 200,
        },
        {
            emirate: "Fujairah",
            minimumOrder: 500,
        },
        {
            emirate: "Ras Al Kaimah",
            minimumOrder: 500,
        },
        {
            emirate: "Abudhabi ",
            minimumOrder: 1000,
        },
    ];

    //DATASOURCE FOR PRODUCT TABLE
    const data = deliveryCharges.map((item, index) => ({
        key: index,
        id: index + 1,
        emirate: item.emirate,
        minimumOrder: item.minimumOrder,
    }));
    // const data =
    //     deliveryCharge?.length > 0 &&
    //     deliveryCharge.map((item, i) => ({
    //         key: item?._id,
    //         id: i + 1,
    //         emirate: item?.emirate,
    //         defaultDeliveryCharge: item?.defaultDeliveryCharge,
    //         minimumOrder: item?.minimumOrder,
    //         deliveryCharge: item?.defaultCharge,
    //         deliveryAvailability: item?.deliveryAvailability,
    //         _id: item?._id,
    //         ...item,
    //     }));

    //COLUMN DETAILS FOR PRODUCTS TABLE
    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "_id",
            render: (text) => <p className='text-center'>{text}</p>,
            align: "center",
        },
        {
            title: "Emirate",
            dataIndex: "emirate",
            key: "emirate",
            render: (emirate) => <span>{emirate}</span>,
            // width: 100,
            align: "center",
        },
        {
            title: "Minimum Order",
            dataIndex: "minimumOrder",
            key: "minimumOrder",
            render: (minimumOrder) => (
                <span>AED {Number(minimumOrder).toFixed(2)}</span>
            ),
            align: "center",
        },
        {
            title: "Delivery Charge",
            render: () => <span>AED {(0).toFixed(2)}</span>,
            align: "center",
        },
        // {
        //     title: "Default Delivery Charge",
        //     dataIndex: "defaultCharge",
        //     key: "defaultCharge",
        //     render: (defaultCharge) => (
        //         <span>{`AED ${Number(defaultCharge)?.toFixed(2)}`}</span>
        //     ),
        //     align: "center",
        // },
        // {
        //     title: "Minimum Order",
        //     key: "minimumOrder",
        //     dataIndex: "minimumOrder",
        //     render: (minimumOrder) => (
        //         <span>{`AED ${Number(minimumOrder)?.toFixed(2)}`}</span>
        //     ),
        //     align: "center",
        // },
        // {
        //     title: "Delivery Charge",
        //     key: "deliveryCharge",
        //     dataIndex: "deliveryCharge",
        //     render: (deliveryCharge) => (
        //         <span>{`AED ${Number(deliveryCharge)?.toFixed(2)}`}</span>
        //     ),
        //     align: "center",
        // },
        // {
        //     title: "Delivery Availability",
        //     key: "isAvailable",
        //     render: (data) => {
        //         return data.isAvailable ? (
        //             <Tag color='#87d068'>Visible</Tag>
        //         ) : (
        //             <Tag color='#f50'>Invisible</Tag>
        //         );
        //     },
        //     align: "center",
        // },
        // {
        //     title: "Action",
        //     key: "action",
        //     align: "center",
        //     render: (item) => (
        //         <Space size='middle'>
        //             <Button
        //                 shape='circle'
        //                 disabled
        //                 icon={<EditFilled />}
        //                 style={{ border: "none" }}
        //                 onClick={(e) => {
        //                     e.stopPropagation();
        //                     navigate(`/edit-box/${item._id}`, {
        //                         state: { data: item },
        //                     });
        //                 }}
        //             />
        //             <Popconfirm
        //                 title='Are you sure to delete this box?'
        //                 onConfirm={(e) => {
        //                     e.stopPropagation();
        //                     deleteDeliveryCharge(item?._id);
        //                 }}
        //                 onCancel={(e) => {
        //                     e.stopPropagation();
        //                 }}
        //                 okText='Yes'
        //                 cancelText='No'
        //             >
        //                 <Button
        //                     shape='circle'
        //                     disabled
        //                     icon={<DeleteFilled />}
        //                     className='text-danger'
        //                     style={{ border: "none" }}
        //                 />
        //             </Popconfirm>
        //             {/* <MoreOutlined /> */}
        //         </Space>
        //     ),
        // },
    ];

    const LeftComponent = () => (
        <div>
            <Breadcrumb separator='>'>
                <Breadcrumb.Item>Settings</Breadcrumb.Item>
                <Breadcrumb.Item>Delivery Charges</Breadcrumb.Item>
            </Breadcrumb>
            <h3 className={styles.heading}>Delivery Charges</h3>
        </div>
    );

    const RightComponent = () => (
        <Row>
            <Space>
                {/* <Button
                    type='primary'
                    // disabled
                    icon={<PlusOutlined />}
                    size='large'
                    className={styles.btn}
                    onClick={() => navigate("/add-delivery-charge")}
                >
                    Add Delivery Charges
                </Button> */}
            </Space>
        </Row>
    );

    return (
        <div className={styles.DeliveryChargeWrapper}>
            <Topbar
                LeftComponent={LeftComponent}
                RightComponent={RightComponent}
            />

            <div className={styles.deliveryChargeContainer}>
                <Table
                    columns={columns}
                    dataSource={data}
                    // loading={isLoading}
                />
            </div>
            {/* <Modal
                footer={null}
                title='Edit Product'
                open={isModalVisible !== null}
                setIsModalVisible={setIsModalVisible}
                onCancel={() => setIsModalVisible(null)}
                isModalVisible={isModalVisible}
                destroyOnClose={true}
                width='1000px'
            >
                <EditProduct
                    detail={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    fetchDeliveryCharges={fetchDeliveryCharges}
                />
            </Modal> */}
        </div>
    );
};

export default DeliveryCharge;
