import { useCallback, useEffect, useRef, useState } from "react";
import { ExclamationCircleOutlined, PrinterFilled } from "@ant-design/icons";
import {
    Breadcrumb,
    Button,
    Col,
    Divider,
    Input,
    message,
    Modal,
    Row,
    Select,
    Steps,
    Table,
} from "antd";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { titleCase, token } from "../../utils";
import { IMAGE_URL } from "../../utils/constants";
import PrintInvoice from "../../components/PrintInvoice";
import Topbar from "../../components/TopBar";
import styles from "./OrderDetail.module.scss";
import PrivateAxios from "../../services/axiosService";

const { Option } = Select;
const { TextArea } = Input;

function OrderDetail() {
    const { orderId: id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [orderDetail, setOrderDetail] = useState([]);
    const [isOrder, setOrder] = useState(true);
    const [isDelivery, setDelivery] = useState(true);
    const [orderStatus, setOrderStatus] = useState("");
    const [orderText, setOrderText] = useState("");
    const componentRef = useRef();

    //FETCH SINGLE ORDER
    const fetchOrderHandler = useCallback(() => {
        setIsLoading(true);
        const config = {
            headers: {
                "x-auth-token": token(),
            },
        };
        PrivateAxios.get(`/orders/${id}`, config)
            .then((response) => {
                setOrderDetail(response?.data);
                setOrderStatus(response?.data?.orderStatus);
                setIsLoading(false);
            })
            .catch((error) => {
                message.error(
                    error?.response?.data?.message || "Something went wrong!"
                );
                setIsLoading(false);
            });
    }, [id]);

    useEffect(() => {
        fetchOrderHandler();
    }, [fetchOrderHandler]);

    const LeftComponent = () => (
        <div>
            <Breadcrumb separator='>'>
                <Breadcrumb.Item>
                    <Link to='/'>Orders</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Order Details</Breadcrumb.Item>
                <Breadcrumb.Item>{orderDetail?.orderId}</Breadcrumb.Item>
            </Breadcrumb>
            <h3 className={styles.heading}>
                Order from {orderDetail?.user?.fullName}
            </h3>
        </div>
    );
    const RightComponent = () => <div />;

    //DATASOURCE FOR ORDER TABLE
    const dataSource = orderDetail?.orderItems?.map((item) => ({
        key: item?._id ? item._id : item.box._id,
        img: IMAGE_URL + item?.mainImage,
        ...item,
    }));

    //COLUMNS FOR TABLE
    const columns = [
        {
            title: "Products",
            key: "name",

            render: (data) => (
                <Row gutter={10}>
                    <Col>
                        <img
                            src={data?.img}
                            alt='product'
                            className={styles.prodImg}
                        />
                    </Col>
                    <Col>
                        <p className={styles.prodName}>{data?.name}</p>
                        <p className={styles.prodPrice}>
                            AED {Number(data?.price).toFixed(2)}
                        </p>
                    </Col>
                </Row>
            ),
        },
        {
            title: "Quantity",
            dataIndex: "qty",
            key: "qty",
            align: "center",
        },
        {
            title: "Price",
            key: "total",
            align: "center",
            render: (data) => <span>AED {data?.total?.toFixed(2)}</span>,
        },
    ];

    //UPDATE STATUS OF ORDER
    const updateOrderStatusHandler = () => {
        Modal.confirm({
            title: "Change Order Status",
            icon: <ExclamationCircleOutlined />,
            content: (
                <span>
                    Are you sure you want to chage order status to
                    <strong className={styles.orderStatus}>
                        {" "}
                        {orderStatus}
                    </strong>
                    ?
                </span>
            ),
            okText: "Sure",
            cancelText: "Cancel",
            onOk: () => {
                const data = JSON.stringify({
                    status: orderStatus,
                    note: orderText,
                });
                const config = {
                    headers: {
                        "x-auth-token": token(),
                        "Content-Type": "application/json",
                    },
                };
                PrivateAxios.patch(`/orders/${id}`, data, config)
                    .then((response) => {
                        response && message.success(response?.data?.message);
                        setOrderText("");
                        setOrderDetail(response?.data?.data);
                    })
                    .catch(function (error) {
                        message.error(
                            error?.response?.data?.message ||
                                "Something went wrong!"
                        );
                    });
            },
        });
    };

    return (
        <div className={styles.OrderDetailWrapper}>
            <Topbar
                LeftComponent={LeftComponent}
                RightComponent={RightComponent}
            />
            <div className={styles.orderContainer}>
                <Row className={styles.headerWrap} justify='space-between'>
                    <Col className='text-center'>
                        <p className={styles.label}>Order Id</p>
                        <p className={styles.id}>{orderDetail?.orderId}</p>
                    </Col>
                    <Col className='text-center'>
                        <p className={styles.label}>Products</p>
                        <p className={styles.products}>
                            {orderDetail?.orderItems?.length}
                        </p>
                    </Col>
                    <Col className='text-center'>
                        <p className={styles.label}>Price</p>
                        <p className={styles.priceValue}>
                            AED {orderDetail?.subTotal?.toFixed(2)}
                        </p>
                    </Col>
                    <Col className='text-center'>
                        <p className={styles.label}>Ordered On</p>
                        <p className={styles.value}>
                            {moment(orderDetail?.createdAt).format(
                                "DD-MM-YY, hh:mm A"
                            )}
                        </p>
                    </Col>
                    <Col className='text-center'>
                        <p className={styles.label}>Delivered On</p>
                        <p className={styles.value}>
                            {orderDetail?.orderStatus === "delivered"
                                ? moment(
                                      orderDetail?.updates[
                                          orderDetail.updates.length - 1
                                      ]?.date
                                  )?.format("DD/MM/YY, hh:mm A")
                                : "Not yet delivered"}
                        </p>
                    </Col>
                    <Col className='text-center'>
                        <p className={styles.label}>Payment Method</p>
                        <p className={styles.value}>
                            {orderDetail?.paymentType === "cod"
                                ? "COD"
                                : "Online"}
                        </p>
                    </Col>
                    <Col className='text-center'>
                        <p className={styles.label}>Status</p>
                        <p className={styles.statusValue}>
                            {orderDetail?.orderStatus || "-"}
                        </p>
                    </Col>
                    <Col className='text-center'>
                        <ReactToPrint
                            trigger={() => (
                                <button className='border-0 p-2 rounded bg-light'>
                                    <p className={styles.label}>Print Order</p>
                                    <PrinterFilled
                                        className={styles.products}
                                    />
                                </button>
                            )}
                            content={() => componentRef.current}
                        />
                    </Col>
                </Row>
                <Row justify='space-between' className='py-2'>
                    <Col span={16} className='bg-white p-3'>
                        <Table
                            loading={isLoading}
                            pagination={dataSource?.length > 10}
                            dataSource={dataSource}
                            columns={columns}
                        />
                        <div className='p-3 bg-light'>
                            <Row>
                                <Col span={16}>
                                    <h6>Product Details</h6>
                                </Col>
                                <Col span={8}>
                                    <Row gutter={10} justify='space-between'>
                                        <Col>
                                            <p className={styles.subTotal}>
                                                Sub total ({dataSource?.length}{" "}
                                                items)
                                            </p>
                                        </Col>
                                        <Col>
                                            <p className={styles.subTotal}>
                                                AED{" "}
                                                <strong>
                                                    {orderDetail?.subTotal?.toFixed(
                                                        2
                                                    )}
                                                </strong>
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row gutter={10} justify='space-between'>
                                        <Col>
                                            <p className={styles.subTotal}>
                                                Delivery Fee
                                            </p>
                                        </Col>
                                        <Col>
                                            <p className={styles.subTotal}>
                                                AED{" "}
                                                <strong>
                                                    {orderDetail?.deliveryCharge?.toFixed(
                                                        2
                                                    )}
                                                </strong>
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Divider className='my-2' />

                            <Row align='middle' justify='end'>
                                <Col>
                                    <Row gutter={20}>
                                        <Col>
                                            <p className='text-success mb-0'>
                                                <strong>Total Paid</strong>
                                            </p>
                                        </Col>
                                        <Col>
                                            <p className='text-success mb-0'>
                                                AED{" "}
                                                <strong>
                                                    {orderDetail?.netTotal?.toFixed(
                                                        2
                                                    )}
                                                </strong>
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col span={7} className='bg-white w-25'>
                        <Row>
                            <Col span={12}>
                                <Button
                                    className={
                                        isOrder ? styles.tab : styles.tabActive
                                    }
                                    type='text'
                                    onClick={() => setOrder(true)}
                                >
                                    <strong>Change Order Status</strong>
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button
                                    className={
                                        isOrder ? styles.tabActive : styles.tab
                                    }
                                    type='text'
                                    onClick={() => setOrder(false)}
                                >
                                    <strong>Order History</strong>
                                </Button>
                            </Col>
                        </Row>
                        <div className='px-4 py-3'>
                            {isOrder ? (
                                <div>
                                    <Select
                                        className='w-100'
                                        value={orderStatus}
                                        onChange={(event) =>
                                            setOrderStatus(event)
                                        }
                                    >
                                        <Option value='placed'>Placed</Option>
                                        <Option value='processing'>
                                            Processing
                                        </Option>
                                        <Option value='delivered'>
                                            Delivered
                                        </Option>
                                        <Option value='cancelled'>
                                            Cancelled
                                        </Option>
                                    </Select>
                                    <TextArea
                                        rows={3}
                                        placeholder='Add Customer Note'
                                        className='my-2'
                                        onChange={(event) =>
                                            setOrderText(event?.target?.value)
                                        }
                                        value={orderText}
                                    />
                                    <Button
                                        type='primary'
                                        className='w-100'
                                        onClick={() =>
                                            updateOrderStatusHandler()
                                        }
                                    >
                                        Update Order Status
                                    </Button>
                                    <Divider className='my-3' />
                                    <Row align='middle' justify='space-between'>
                                        <Col className='fw-bold'>
                                            Buyer Details
                                        </Col>
                                        <Col />
                                    </Row>
                                    <Divider className='my-2' />
                                    <Row gutter={10}>
                                        <Col span={4}>
                                            <p className={styles.title}>Name</p>
                                        </Col>
                                        <Col>
                                            <p className={styles.titleName}>
                                                {orderDetail?.user?.fullName}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row gutter={10}>
                                        <Col span={4}>
                                            <p className={styles.title}>
                                                Email
                                            </p>
                                        </Col>
                                        <Col>
                                            <p className={styles.titleName}>
                                                {orderDetail?.user?.email}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row gutter={10}>
                                        <Col span={4}>
                                            <p className={styles.title}>
                                                Phone
                                            </p>
                                        </Col>
                                        <Col>
                                            <p className={styles.titleName}>
                                                {
                                                    orderDetail?.user
                                                        ?.mobileNumber
                                                }
                                            </p>
                                        </Col>
                                    </Row>
                                    <Divider className='my-3' />
                                    <div className='p-2 bg-light'>
                                        <Row>
                                            <Col>
                                                <Button
                                                    className={`${
                                                        isDelivery
                                                            ? styles.tabActive
                                                            : styles.tab
                                                    } fw-bold`}
                                                    type='text'
                                                    onClick={() =>
                                                        setDelivery(true)
                                                    }
                                                >
                                                    Shipping Address
                                                </Button>
                                            </Col>
                                        </Row>
                                        <div className='p-3'>
                                            {
                                                orderDetail?.deliveryAddress
                                                    ?.fullName
                                            }
                                            <br />
                                            {
                                                orderDetail?.deliveryAddress
                                                    ?.address
                                            }{" "}
                                            <br />{" "}
                                            {
                                                orderDetail?.deliveryAddress
                                                    ?.building
                                            }{" "}
                                            <br />
                                            {
                                                orderDetail?.deliveryAddress
                                                    ?.locality
                                            }
                                            <br />
                                            {
                                                orderDetail?.deliveryAddress
                                                    ?.landmark
                                            }
                                            <br />
                                            {
                                                orderDetail?.deliveryAddress
                                                    ?.emirates?.name
                                            }
                                            <br />
                                            {
                                                orderDetail?.deliveryAddress
                                                    ?.mobileNumber
                                            }
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <Steps
                                    progressDot
                                    direction='vertical'
                                    current={orderDetail?.updates?.length - 1}
                                    items={orderDetail?.updates?.map(
                                        (item) => ({
                                            title: titleCase(item?.status),
                                            description: `${moment(
                                                item?.date
                                            ).format("DD-MM-YY, hh:ss A")}, ${
                                                item?.note || ""
                                            }`,
                                        })
                                    )}
                                />
                            )}
                        </div>
                    </Col>
                </Row>
                <div className='d-none'>
                    <PrintInvoice
                        ref={componentRef}
                        tableData={dataSource}
                        orderDetail={orderDetail}
                    />
                </div>
            </div>
        </div>
    );
}

export default OrderDetail;
