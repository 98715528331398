import { Col, Divider, Row, Table } from "antd";
import React from "react";
import styles from "./PrintInvoice.module.scss";
import moment from "moment";

const PrintInvoice = React.forwardRef((props, ref) => {
    const { orderDetail, tableData } = props;

    const columns = [
        {
            title: "Products",
            key: "name",
            render: (data) => <p className={styles.prodName}>{data?.name}</p>,
        },

        {
            title: "Quantity",
            dataIndex: "qty",
            key: "qty",
            align: "center",
        },
        {
            title: "Price",
            key: "price",
            align: "center",
            render: (data) => <span> AED {data?.total?.toFixed(2)}</span>,
        },
    ];
    return (
        <div className={styles.PrintInvoiceWrapper} ref={ref} props={props}>
            <h4>Order Details</h4>
            <Row align='middle'>
                <Col>
                    <p className='mb-0'>
                        Ordered on{" "}
                        {moment(orderDetail?.createdAt)?.format(
                            "DD/MM/YY, hh:mm A"
                        )}
                    </p>
                </Col>
                <Col>
                    <Divider type='vertical' />
                </Col>
                <Col>
                    <p className='mb-0'> Order No #{orderDetail.orderId} </p>
                </Col>
            </Row>
            <Row className='p-3 border rounded my-3' gutter={10}>
                <Col span={12}>
                    <h6>Shipping Address</h6>
                    <p>
                        {orderDetail?.deliveryAddress?.fullName}
                        <br />
                        {orderDetail?.deliveryAddress?.address} <br />
                        {orderDetail?.deliveryAddress?.building} <br />
                        {orderDetail?.deliveryAddress?.locality}
                        <br />
                        {orderDetail?.deliveryAddress?.landmark}
                        <br />
                        {orderDetail?.deliveryAddressy?.emirates?.name}
                        {orderDetail?.deliveryAddress?.mobileNumber}
                    </p>
                </Col>
                <Col span={12}>
                    <h6>Order Summary</h6>
                    <Row align='middle' justify='space-between'>
                        <Col>Payment Method:</Col>
                        <Col>
                            {orderDetail?.paymenttype === "cod"
                                ? "COD"
                                : "Online"}
                        </Col>
                    </Row>
                    <Row align='middle' justify='space-between'>
                        <Col>Subtotal:</Col>
                        <Col>AED {orderDetail?.subTotal?.toFixed(2)}</Col>
                    </Row>
                    <Row align='middle' justify='space-between'>
                        <Col>Shipping Charge:</Col>
                        <Col>AED {orderDetail?.deliveryCharge?.toFixed(2)}</Col>
                    </Row>
                    <Row
                        align='middle'
                        justify='space-between'
                        className='mt-3'
                    >
                        <Col>
                            <strong>Grand Total:</strong>
                        </Col>
                        <Col>
                            <strong>
                                AED {orderDetail?.netTotal?.toFixed(2)}
                            </strong>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className='p-3 border rounded my-3' gutter={10}>
                <Col span={24}>
                    {orderDetail.orderStatus === "delivered" && (
                        <h6>
                            Delivered{" "}
                            {moment(
                                orderDetail?.updates[
                                    orderDetail.updates.length - 1
                                ].date
                            )?.format("DD/MM/YY, hh:mm A")}
                        </h6>
                    )}
                    <Table
                        pagination={false}
                        dataSource={tableData}
                        columns={columns}
                    />
                </Col>
            </Row>
        </div>
    );
});

export default PrintInvoice;
