import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import {
    Button,
    Col,
    Divider,
    Input,
    message,
    Row,
    Switch,
    Upload,
} from "antd";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import PrivateAxios from "../../services/axiosService";
import { getUrlExtension, token } from "../../utils";
import { IMAGE_URL } from "../../utils/constants";
import styles from "./AddVariant.module.scss";

const { TextArea } = Input;

const AddVariant = ({ data, title, setProduct, setModalData, productId }) => {
    const [loading, setLoading] = useState(false);

    const initialValues = {
        name: data?.name ?? "",
        description: data?.description ?? "",
        price: data?.price ?? "",
        slug: data?.slug ?? "",
        isActive: data?.isActive ?? true,
        primaryImage: data?.images?.mainImage
            ? [{ thumbUrl: IMAGE_URL + data.images.mainImage }]
            : [],
        galleryImages:
            data?.images?.gallery?.length > 0
                ? data?.images?.gallery.map((image) => ({
                      thumbUrl: IMAGE_URL + image,
                  }))
                : [],
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, "Too Short!")
            .max(250, "Too Long!")
            .required("Please enter the product name"),
        description: Yup.string()
            .required("Please enter detailed description")
            .min(1, "Too Short!"),
        slug: Yup.string().required("Please enter a slug"),
        price: Yup.number()
            .typeError("Please enter a valid price")
            .required("Please enter the price"),
        primaryImage: Yup.array()
            .min(1, "Please choose an image")
            .required("Please choose an image"),
        galleryImages: Yup.array()
            .min(1, "Please choose an image")
            .required("Please choose an image"),
        isActive: Yup.bool(),
    });

    const handleImageUpload = async (formData, mainImage, gallery) => {
        await imageAppendHandler(mainImage[0], formData, "mainImage");
        await Promise.all(
            gallery.map(async (item) => {
                await imageAppendHandler(item, formData, "gallery");
            })
        );

        return true;
    };

    //APPEND IMAGES TO FORM-DATA
    const imageAppendHandler = async (image, data, name) => {
        if (image?.originFileObj) {
            await data.append(name, image?.originFileObj);
        } else {
            const url = image.thumbUrl;
            const imgExt = getUrlExtension(url);
            const fileName = `myFile.${imgExt}`;
            let file;
            await fetch(url).then(async (response) => {
                const blob = await response.blob();
                file = new File([blob], fileName, {
                    type: blob.type,
                });
                data.append(name, file);
            });
            return file;
        }
    };

    const addVariantHandler = async (values) => {
        setLoading(true);

        let formData = new FormData();
        formData.append("name", values.name);
        formData.append("description", values.description);
        formData.append("isActive", values.isActive);
        formData.append("slug", values.slug);
        formData.append("price", values.price);
        if (data) {
            await handleImageUpload(
                formData,
                values.primaryImage,
                values.galleryImages
            );
        } else {
            formData.append("mainImage", values.primaryImage[0]?.originFileObj);
            for (const image of values.galleryImages) {
                formData.append("gallery", image?.originFileObj);
            }
        }

        const config = {
            headers: {
                "x-auth-token": token(),
                "Content-Type": "multipart/form-data",
            },
        };

        if (data) {
            PrivateAxios.patch(`/variants/${data?._id}`, formData, config)
                .then(function (response) {
                    setLoading(false);
                    setProduct((prevState) => {
                        let objIndex = prevState.findIndex(
                            (obj) => obj._id === data?._id
                        );
                        if (objIndex > -1) {
                            prevState[objIndex] = response?.data?.data;
                        }
                        return [...prevState];
                    });
                    setModalData({ open: false, title: "", data: "" });
                    response && message.success(response?.data?.message);
                })
                .catch(function (error) {
                    message.error(error?.response?.data?.message);
                    setLoading(false);
                });
        } else {
            PrivateAxios.post(
                `/products/${productId}/variants`,
                formData,
                config
            )
                .then(function (response) {
                    setLoading(false);
                    setProduct((prevState) => [
                        ...prevState,
                        response?.data?.data,
                    ]);
                    setModalData({ open: false, title: "", data: "" });
                    response && message.success(response?.data?.message);
                })
                .catch(function (error) {
                    message.error(error?.response?.data?.message);
                    setLoading(false);
                });
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: addVariantHandler,
    });
    // console.log(formik.values);

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <>
            <div className={styles.AddVariantWrapper}>
                <div>
                    <h3 className={styles.heading}>{title}</h3>
                    <Divider />
                </div>
                <div className={styles.addVariantContainer}>
                    <div className={styles.content}>
                        <div className='mb-3 mt-2'>
                            <p>
                                Variant Name
                                <span className='text-danger'>*</span>
                            </p>
                            <Input
                                placeholder='Variant name'
                                onChange={formik.handleChange("name")}
                                onBlur={formik.handleBlur("name")}
                                value={formik.values.name}
                            />{" "}
                            {formik.errors.name && formik.touched.name ? (
                                <pre className='text-danger'>
                                    {formik.errors.name}
                                </pre>
                            ) : null}
                        </div>

                        <div className='mb-3'>
                            <p>
                                Description
                                <span className='text-danger'>*</span>
                            </p>
                            <TextArea
                                rows={6}
                                placeholder='Description'
                                onChange={formik.handleChange("description")}
                                onBlur={formik.handleBlur("description")}
                                value={formik.values.description}
                            />
                            {formik.errors.description &&
                            formik.touched.description ? (
                                <pre className='text-danger'>
                                    {formik.errors.description}
                                </pre>
                            ) : null}
                        </div>
                        <div className='mb-3'>
                            <p>
                                Price
                                <span className='text-danger'>*</span>
                            </p>
                            <Input
                                addonBefore='AED'
                                placeholder='Price'
                                onChange={formik.handleChange("price")}
                                onBlur={formik.handleBlur("price")}
                                value={formik.values.price}
                                type='number'
                                min={1}
                            />
                            {formik.errors.price && formik.touched.price ? (
                                <pre className='text-danger'>
                                    {formik.errors.price}
                                </pre>
                            ) : null}
                        </div>

                        <Row className='mb-3 mt-4'>
                            <span className='me-3'>Is Visible?</span>
                            <Switch
                                checked={formik.values.isActive}
                                onChange={(value) =>
                                    formik.setFieldValue("isActive", value)
                                }
                            />
                        </Row>
                        <div className='pt-3'>
                            <p>
                                Slug
                                <span className='text-danger'>*</span>
                            </p>
                            <Input
                                placeholder='Enter Slug'
                                onChange={formik.handleChange("slug")}
                                onBlur={formik.handleBlur("slug")}
                                value={formik.values.slug}
                            />

                            {formik.errors.slug && formik.touched.slug ? (
                                <pre className='text-danger'>
                                    {formik.errors.slug}
                                </pre>
                            ) : null}
                        </div>
                        <span className='d-flex align-items-center'>
                            <h5 className='text-bold mt-3'>Images</h5>
                        </span>
                        <Row gutter={10}>
                            <Col>
                                <p>
                                    Main Image{" "}
                                    <span className='text-danger'>*</span>
                                </p>
                                <Upload
                                    listType='picture-card'
                                    className='avatar-uploader'
                                    showUploadList={true}
                                    onChange={(e) => {
                                        formik.handleBlur("primaryImage");
                                        formik.setFieldValue(
                                            "primaryImage",
                                            e.fileList
                                        );
                                    }}
                                    beforeUpload={() => false}
                                    accept='image/*'
                                    fileList={formik.values.primaryImage}
                                    maxCount={1}
                                    onRemove={() =>
                                        formik.setFieldValue("primaryImage", [])
                                    }
                                >
                                    {uploadButton}
                                </Upload>
                                {formik.touched.primaryImage &&
                                formik.errors.primaryImage ? (
                                    <pre className='text-danger'>
                                        {formik.errors.primaryImage}
                                    </pre>
                                ) : null}
                            </Col>
                            <Col>
                                <div className='pl-3'>
                                    <p>Gallery Image (Max 4)</p>
                                    <Upload
                                        listType='picture-card'
                                        className='avatar-uploader'
                                        showUploadList={true}
                                        onChange={(e) => {
                                            formik.handleBlur("galleryImages");
                                            formik.setFieldValue(
                                                "galleryImages",
                                                e.fileList
                                            );
                                        }}
                                        beforeUpload={() => false}
                                        accept='image/*'
                                        fileList={formik.values.galleryImages}
                                        maxCount={4}
                                        onRemove={({ uid }) =>
                                            formik.setFieldValue(
                                                "galleryImages",
                                                formik.values.galleryImages.filter(
                                                    (image) => image.uid !== uid
                                                )
                                            )
                                        }
                                    >
                                        {uploadButton}
                                    </Upload>
                                    {formik.touched.galleryImages &&
                                    formik.errors.galleryImages ? (
                                        <pre className='text-danger'>
                                            {formik.errors.galleryImages}
                                        </pre>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <div className='w-100 d-flex align-items-center justify-content-center'>
                            <Button
                                onClick={formik.handleSubmit}
                                type='primary'
                                icon={<SaveOutlined />}
                                size='large'
                                loading={loading}
                                className='d-flex align-items-center'
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddVariant;
