import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import {
    Breadcrumb,
    Button,
    Col,
    Input,
    message,
    Row,
    Select,
    Switch,
    Tabs,
    Upload,
} from "antd";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Topbar from "../../components/TopBar";
import PrivateAxios from "../../services/axiosService";
import { token } from "../../utils";
import styles from "./AddProduct.module.scss";

const { TextArea } = Input;
const { Option } = Select;

const initialValues = {
    name: "",
    description: "",
    price: "",
    category: "",
    slug: "",
    isVisible: true,
    primaryImage: [],
    galleryImages: [],
};

const AddProduct = () => {
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isVariant, setIsVariant] = useState(true);

    const LeftComponent = () => (
        <div>
            <Breadcrumb separator='>'>
                <Breadcrumb.Item href='/products'>Catalog</Breadcrumb.Item>
                <Breadcrumb.Item href='/products'>Products</Breadcrumb.Item>
            </Breadcrumb>
            <h3 className={styles.heading}> Add Products</h3>
        </div>
    );

    const RightComponent = () => <div />;

    const variationSchema = {
        name: Yup.string()
            .min(2, "Too Short!")
            .max(250, "Too Long!")
            .required("Please enter the product name"),
        description: Yup.string()
            .required("Please enter detailed description")
            .min(1, "Too Short!"),
        category: Yup.string().required("Please choose category"),
        slug: Yup.string().required("Please enter a slug"),
        primaryImage: Yup.array()
            .min(1, "Please choose an image")
            .required("Please choose an image"),
        isVisible: Yup.bool(),
    };

    const withoutVariationSchema = {
        price: Yup.number()
            .typeError("Please enter a valid price")
            .min(1, "Price should be greate than or equal to 1")
            .required("Enter price of product "),
        // galleryImages: Yup.array()
        //     .min(1, "Please choose an image")
        //     .required("Please choose an image"),
    };

    const validationSchema = Yup.object().shape(
        isVariant
            ? { ...variationSchema }
            : { ...variationSchema, ...withoutVariationSchema }
    );

    const addProductHandler = (values) => {
        setLoading(true);
        let formData = new FormData();
        formData.append("name", values.name);
        formData.append("description", values.description);
        formData.append("category", values.category);
        formData.append("isActive", values.isVisible);
        formData.append("slug", values.slug);
        formData.append("mainImage", values.primaryImage[0]?.originFileObj);
        if (!isVariant) {
            formData.append("price", values.price);
            for (const image of values.galleryImages) {
                formData.append("gallery", image?.originFileObj);
            }
        }

        const config = {
            headers: {
                "x-auth-token": token(),
                "Content-Type": "multipart/form-data",
            },
        };

        let query;
        if (isVariant) {
            query = "productType=variant";
        }

        PrivateAxios.post(`/products?${query}`, formData, config)
            .then(function (response) {
                setLoading(false);
                response && message.success(response?.data?.message);
                navigate("/products");
            })
            .catch(function (error) {
                message.error(error?.response?.data?.message);
                setLoading(false);
            });
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: addProductHandler,
    });

    //FETCH ALL CATEGORIES
    const fetchCategoryHandler = () => {
        let config = {
            headers: {
                "x-auth-token": token(),
            },
        };
        PrivateAxios.get("/categories?filter=all", config)
            .then(function (response) {
                setCategories(response?.data);
            })
            .catch(function (error) {
                message.error(error?.response?.data?.message);
            });
    };

    useEffect(() => {
        if (categories?.length === 0) fetchCategoryHandler();
    }, [categories?.length]);

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    //ITEMS FOR ORDER STATUS TABS
    const tabItems = [
        {
            label: "Product with Variants",
            key: "with-variant",
            children: (
                <>
                    <div className='mb-3 mt-2'>
                        <p>
                            Product Name
                            <span className='text-danger'>*</span>
                        </p>
                        <Input
                            placeholder='Product name'
                            onChange={formik.handleChange("name")}
                            onBlur={formik.handleBlur("name")}
                            value={formik.values.name}
                        />{" "}
                        {formik.errors.name && formik.touched.name ? (
                            <pre className='text-danger'>
                                {formik.errors.name}
                            </pre>
                        ) : null}
                    </div>
                    <div className='mb-3'>
                        <p>
                            Description
                            <span className='text-danger'>*</span>
                        </p>
                        <TextArea
                            rows={6}
                            placeholder='Description'
                            onChange={formik.handleChange("description")}
                            onBlur={formik.handleBlur("description")}
                            value={formik.values.description}
                        />
                        {formik.errors.description &&
                        formik.touched.description ? (
                            <pre className='text-danger'>
                                {formik.errors.description}
                            </pre>
                        ) : null}
                    </div>
                    <div className='mb-4'>
                        <p>
                            Category
                            <span className='text-danger'>*</span>
                        </p>
                        <Select
                            placeholder='Select a category'
                            className='w-100'
                            value={formik.values.category}
                            onChange={formik.handleChange("category")}
                            onBlur={formik.handleBlur("category")}
                        >
                            <Option value=''>Select</Option>
                            {categories?.map((item) => {
                                return (
                                    <Option key={item._id} value={item._id}>
                                        {item.name}
                                    </Option>
                                );
                            })}
                        </Select>
                        {formik.errors.category && formik.touched.category ? (
                            <pre className='text-danger'>
                                {formik.errors.category}
                            </pre>
                        ) : null}
                    </div>
                    <Row className='mb-3'>
                        <span className='me-3'>Is Visible?</span>
                        <Switch
                            checked={formik.values.isVisible}
                            onChange={(e) =>
                                formik.setFieldValue(
                                    "isVisible",
                                    e?.target?.checked
                                )
                            }
                        />
                    </Row>
                    <div className='pt-3 mb-3'>
                        <p>
                            Slug
                            <span className='text-danger'>*</span>
                        </p>
                        <Input
                            placeholder='Enter Slug'
                            onChange={formik.handleChange("slug")}
                            onBlur={formik.handleBlur("slug")}
                            value={formik.values.slug}
                        />

                        {formik.errors.slug && formik.touched.slug ? (
                            <pre className='text-danger'>
                                {formik.errors.slug}
                            </pre>
                        ) : null}
                    </div>
                    <Row>
                        <Col>
                            <p>
                                Primary Image{" "}
                                <span className='text-danger'>*</span>
                            </p>
                            <Upload
                                listType='picture-card'
                                className='avatar-uploader'
                                showUploadList={true}
                                onChange={(e) => {
                                    formik.handleBlur("primaryImage");
                                    formik.setFieldValue(
                                        "primaryImage",
                                        e.fileList
                                    );
                                }}
                                beforeUpload={() => false}
                                accept='image/*'
                                fileList={formik.values.primaryImage}
                                maxCount={1}
                                onRemove={() =>
                                    formik.setFieldValue("primaryImage", [])
                                }
                            >
                                {uploadButton}
                            </Upload>
                            {formik.touched.primaryImage &&
                            formik.errors.primaryImage ? (
                                <pre className='text-danger'>
                                    {formik.errors.primaryImage}
                                </pre>
                            ) : null}
                        </Col>
                    </Row>

                    <div className='w-100 d-flex align-items-center justify-content-center'>
                        <Button
                            onClick={formik.handleSubmit}
                            type='primary'
                            icon={<SaveOutlined />}
                            size='large'
                            loading={loading}
                            className='d-flex align-items-center '
                        >
                            Submit
                        </Button>
                    </div>
                </>
            ),
        },
        {
            label: "Product without Variants",
            key: "without-variant",
            children: (
                <>
                    {" "}
                    <div className='mb-3 mt-2'>
                        <p>
                            Product Name
                            <span className='text-danger'>*</span>
                        </p>
                        <Input
                            placeholder='Product name'
                            onChange={formik.handleChange("name")}
                            onBlur={formik.handleBlur("name")}
                            value={formik.values.name}
                        />{" "}
                        {formik.errors.name && formik.touched.name ? (
                            <pre className='text-danger'>
                                {formik.errors.name}
                            </pre>
                        ) : null}
                    </div>
                    <div className='mb-3'>
                        <p>
                            Description
                            <span className='text-danger'>*</span>
                        </p>
                        <TextArea
                            rows={6}
                            placeholder='Description'
                            onChange={formik.handleChange("description")}
                            onBlur={formik.handleBlur("description")}
                            value={formik.values.description}
                        />
                        {formik.errors.description &&
                        formik.touched.description ? (
                            <pre className='text-danger'>
                                {formik.errors.description}
                            </pre>
                        ) : null}
                    </div>
                    <div className='mb-3'>
                        <p>
                            Price
                            <span className='text-danger'>*</span>
                        </p>
                        <Input
                            addonBefore='AED'
                            placeholder='Price'
                            onChange={formik.handleChange("price")}
                            onBlur={formik.handleBlur("price")}
                            value={formik.values.price}
                            type='number'
                            min={1}
                        />
                        {formik.errors.price && formik.touched.price ? (
                            <pre className='text-danger'>
                                {formik.errors.price}
                            </pre>
                        ) : null}
                    </div>
                    <div className='mb-3'>
                        <p>
                            Category
                            <span className='text-danger'>*</span>
                        </p>
                        <Select
                            placeholder='Select a category'
                            className='w-100'
                            value={formik.values.category}
                            onChange={formik.handleChange("category")}
                            onBlur={formik.handleBlur("category")}
                        >
                            <Option value=''>Select</Option>
                            {categories?.map((item) => {
                                return (
                                    <Option key={item._id} value={item._id}>
                                        {item.name}
                                    </Option>
                                );
                            })}
                        </Select>
                        {formik.errors.category && formik.touched.category ? (
                            <pre className='text-danger'>
                                {formik.errors.category}
                            </pre>
                        ) : null}
                    </div>
                    <Row className='mb-3 mt-4'>
                        <span className='me-3'>Is Visible?</span>
                        <Switch
                            checked={formik.values.isVisible}
                            onChange={(value) =>
                                formik.setFieldValue("isVisible", value)
                            }
                        />
                    </Row>
                    <div className='pt-3'>
                        <p>
                            Slug
                            <span className='text-danger'>*</span>
                        </p>
                        <Input
                            placeholder='Enter Slug'
                            onChange={formik.handleChange("slug")}
                            onBlur={formik.handleBlur("slug")}
                            value={formik.values.slug}
                        />

                        {formik.errors.slug && formik.touched.slug ? (
                            <pre className='text-danger'>
                                {formik.errors.slug}
                            </pre>
                        ) : null}
                    </div>
                    <span className='d-flex align-items-center'>
                        <h5 className='text-bold mt-3'>Images</h5>
                    </span>
                    <Row gutter={10}>
                        <Col>
                            <p>
                                Main Image{" "}
                                <span className='text-danger'>*</span>
                            </p>
                            <Upload
                                listType='picture-card'
                                className='avatar-uploader'
                                showUploadList={true}
                                onChange={(e) => {
                                    formik.handleBlur("primaryImage");
                                    formik.setFieldValue(
                                        "primaryImage",
                                        e.fileList
                                    );
                                }}
                                beforeUpload={() => false}
                                accept='image/*'
                                fileList={formik.values.primaryImage}
                                maxCount={1}
                                onRemove={() =>
                                    formik.setFieldValue("primaryImage", [])
                                }
                            >
                                {uploadButton}
                            </Upload>
                            {formik.touched.primaryImage &&
                            formik.errors.primaryImage ? (
                                <pre className='text-danger'>
                                    {formik.errors.primaryImage}
                                </pre>
                            ) : null}
                        </Col>
                        <Col>
                            <div className='pl-3'>
                                <p>Gallery Image (Max 4)</p>
                                <Upload
                                    listType='picture-card'
                                    className='avatar-uploader'
                                    showUploadList={true}
                                    onChange={(e) => {
                                        formik.handleBlur("galleryImages");
                                        formik.setFieldValue(
                                            "galleryImages",
                                            e.fileList
                                        );
                                    }}
                                    beforeUpload={() => false}
                                    accept='image/*'
                                    fileList={formik.values.galleryImages}
                                    maxCount={4}
                                    onRemove={({ uid }) =>
                                        formik.setFieldValue(
                                            "galleryImages",
                                            formik.values.galleryImages.filter(
                                                (image) => image.uid !== uid
                                            )
                                        )
                                    }
                                >
                                    {uploadButton}
                                </Upload>
                                {formik.touched.galleryImages &&
                                formik.errors.galleryImages ? (
                                    <pre className='text-danger'>
                                        {formik.errors.galleryImages}
                                    </pre>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <div className='w-100 d-flex align-items-center justify-content-center'>
                        <Button
                            onClick={formik.handleSubmit}
                            type='primary'
                            icon={<SaveOutlined />}
                            size='large'
                            loading={loading}
                            className='d-flex align-items-center'
                        >
                            Submit
                        </Button>
                    </div>
                </>
            ),
        },
    ];

    return (
        <>
            <div className={styles.AddProductWrapper}>
                <Topbar
                    LeftComponent={LeftComponent}
                    RightComponent={RightComponent}
                />
                <div className={styles.addProductContainer}>
                    <div className={styles.content}>
                        <Tabs
                            items={tabItems}
                            type='card'
                            onChange={(key) => {
                                key === "with-variant"
                                    ? setIsVariant(true)
                                    : setIsVariant(false);
                                formik.handleReset();
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddProduct;
