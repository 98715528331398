import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Layout, message } from "antd";
// import Layout from "antd/lib/layout/layout";
import Logo from "../../assets/images/logo.webp";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Login.module.scss";
import { BASE_URL } from "../../utils/constants";
// import Layout from "antd/lib/layout/layout";

const Login = () => {
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const onFinish = (values) => {
        setLoading(true);
        const data = JSON.stringify({
            email: values.username,
            password: values.password,
        });

        const config = {
            method: "post",
            url: `${BASE_URL}/login`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                message.success("Authentication Success!");

                localStorage.setItem(
                    "GOLDEN_RIBBON_ADMIN_TOKEN",
                    response?.data?.token
                );
                setLoading(false);
                navigate("/");
            })
            .catch(function (error) {
                message.error(
                    error?.response?.data?.message || "Somrthing went wrong"
                );
                setLoading(false);
            });
    };

    return (
        <Layout>
            <Col className={styles.container}>
                <Form
                    name='normal_login'
                    className={styles.loginForm}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item>
                        <img
                            src={Logo}
                            alt='logo'
                            className={styles.loginLogo}
                        />
                    </Form.Item>
                    <Form.Item
                        name='username'
                        rules={[
                            {
                                required: true,
                                type: "email",
                                message: "Please input your valid Email!",
                            },
                        ]}
                    >
                        <Input
                            type='email'
                            className={styles.formInput}
                            prefix={
                                <UserOutlined
                                    className={styles.siteFormItemIcon}
                                />
                            }
                            placeholder='Username'
                        />
                    </Form.Item>
                    <Form.Item
                        name='password'
                        rules={[
                            {
                                required: true,
                                message: "Please input your Password!",
                            },
                        ]}
                    >
                        <Input
                            className={styles.formInput}
                            prefix={
                                <LockOutlined
                                    className={styles.siteFormItemIcon}
                                />
                            }
                            type='password'
                            placeholder='Password'
                        />
                    </Form.Item>
                    {/* <Form.Item className={styles.loginFormForgot}>
            <a href="/">Forgot password</a>
          </Form.Item> */}

                    <Form.Item>
                        <div className='d-flex justify-content-center w-100'>
                            <Button
                                type='primary'
                                htmlType='submit'
                                className={styles.loginFormButton}
                                loading={isLoading}
                            >
                                Log in
                            </Button>
                            {/* Or{" "}
            <Link to="/sign-up" className={styles.register}>
              register now!
            </Link> */}
                        </div>
                    </Form.Item>
                </Form>
            </Col>
        </Layout>
    );
};

export default Login;
