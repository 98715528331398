import { LoadingOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import {
    Breadcrumb,
    Button,
    Col,
    Input,
    message,
    Row,
    Space,
    Switch,
    Upload,
} from "antd";
import { Formik } from "formik";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Topbar from "../../components/TopBar";
import PrivateAxios from "../../services/axiosService";
import { getUrlExtension, token } from "../../utils";
import styles from "./AddBanner.module.scss";

const AddBanner = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { state } = useLocation();
    const { data, title } = state;
    const LeftComponent = () => (
        <div>
            <Breadcrumb separator='>'>
                <Breadcrumb.Item href='/boxes'>Home</Breadcrumb.Item>
                <Breadcrumb.Item href='/boxes'>Banner</Breadcrumb.Item>
            </Breadcrumb>
            <h3 className={styles.heading}> {title}</h3>
        </div>
    );

    const RightComponent = () => <div />;

    const ProductSchema = Yup.object().shape({
        image: Yup.array()
            .min(1, "Please choose an image")
            .required("Please choose an image"),
        link: Yup.string().required("Please enter the link to navigate"),
        isVisible: Yup.bool(),
    });

    const imageAppendHandler = async (data, image) => {
        const url = image;
        const imgExt = getUrlExtension(url);
        const fileName = `myFile.${imgExt}`;
        let file;
        await fetch(url).then(async (response) => {
            const blob = await response.blob();
            file = new File([blob], fileName, {
                type: blob.type,
            });
            data.append("image", file);
        });
        return file;
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    return (
        <div className={styles.AddBannerWrapper}>
            <Topbar
                LeftComponent={LeftComponent}
                RightComponent={RightComponent}
            />
            <div className={styles.addBannerContainer}>
                <div className={styles.content}>
                    <Formik
                        initialValues={{
                            image: data ? [{ thumbUrl: data.image }] : [],
                            link: data ? data.link : "",
                            isActive: data ? data.isActive : true,
                        }}
                        validationSchema={ProductSchema}
                        onSubmit={async (values) => {
                            setLoading(true);
                            let data = new FormData();
                            data.append("link", values.link);
                            data.append("isActive", values.isActive);

                            if (values.image[0]?.originFileObj) {
                                data.append(
                                    "image",
                                    values.image[0]?.originFileObj
                                );
                            } else {
                                await imageAppendHandler(
                                    data,
                                    values.image[0]?.thumbUrl
                                );
                            }
                            const config = {
                                headers: {
                                    "x-auth-token": token(),
                                    "Content-Type": "multipart/form-data",
                                },
                            };
                            (state?.data
                                ? PrivateAxios.patch(
                                      `/banners/${state?.data?._id}`,
                                      data,
                                      config
                                  )
                                : PrivateAxios.post(`/banners`, data, config)
                            )
                                .then(function (response) {
                                    setLoading(false);
                                    message.success(response?.data?.message);
                                    navigate("/home-management");
                                })
                                .catch(function (error) {
                                    message.error(
                                        error?.response?.data?.message
                                    );
                                    setLoading(false);
                                });
                        }}
                    >
                        {({
                            errors,
                            touched,
                            handleChange,
                            handleSubmit,
                            handleBlur,
                            // handleReset,
                            setFieldValue,
                            values,
                        }) => (
                            <>
                                <Col className='p-1'>
                                    <Row align='middle' className='mb-4'>
                                        <Col span={3}>
                                            <p className={styles.textEnd}>
                                                Image
                                                <span className='text-danger'>
                                                    *
                                                </span>
                                            </p>
                                        </Col>
                                        <Col span={8}>
                                            <Upload
                                                listType='picture-card'
                                                className='avatar-uploader'
                                                showUploadList={true}
                                                onChange={(e) => {
                                                    handleBlur("image");
                                                    setFieldValue(
                                                        "image",
                                                        e.fileList
                                                    );
                                                }}
                                                beforeUpload={() => false}
                                                accept='image/*'
                                                fileList={values.image}
                                                maxCount={1}
                                                onRemove={() =>
                                                    setFieldValue("image", [])
                                                }
                                            >
                                                {uploadButton}
                                            </Upload>
                                            <pre className='text-danger'>
                                                {errors.image &&
                                                touched.image ? (
                                                    <div>{errors.image}</div>
                                                ) : null}
                                            </pre>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className='p-1'>
                                    <Row align='middle' className='mb-4'>
                                        <Col span={3}>
                                            <p className={styles.textEnd}>
                                                Link
                                                <span className='text-danger'>
                                                    *
                                                </span>
                                            </p>
                                        </Col>
                                        <Col span={8}>
                                            <Input
                                                placeholder='https://'
                                                onChange={handleChange("link")}
                                                onBlur={handleBlur("link")}
                                                value={values.link}
                                                size='large'
                                            />
                                            <pre className='text-danger'>
                                                {errors.link && touched.link ? (
                                                    <div>{errors.link}</div>
                                                ) : null}
                                            </pre>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className='p-1'>
                                    <Row align='middle' className='mb-4'>
                                        <Col span={3}>
                                            <p className={styles.textEnd}>
                                                IsActive
                                                <span className='text-danger'>
                                                    *
                                                </span>
                                            </p>
                                        </Col>
                                        <Col span={8}>
                                            <Switch
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "isActive",
                                                        value
                                                    );
                                                }}
                                                checked={values.isActive}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className='p-1'>
                                    <Row align='middle' className='mt-2'>
                                        <Col span={3}></Col>
                                        <Col span={8}>
                                            <Space>
                                                <Button
                                                    type='primary'
                                                    htmlType='submit'
                                                    icon={<SaveOutlined />}
                                                    size='large'
                                                    className='d-flex align-items-center'
                                                    loading={loading}
                                                    onClick={handleSubmit}
                                                >
                                                    Save
                                                </Button>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Col>
                            </>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default AddBanner;
