/* eslint-disable react/prop-types */
import { CloseOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import {
    Button,
    Col,
    Input,
    message,
    Row,
    Space,
    Switch,
    // TreeSelect,
    Upload,
} from "antd";
// import { TreeNode } from "antd/lib/tree-select";
import PrivateAxios from "../../services/axiosService";
import { Formik } from "formik";
import { useState } from "react";
import { getUrlExtension, token } from "../../utils";
import { IMAGE_URL } from "../../utils/constants";
import * as Yup from "yup";

import styles from "./EditCategory.module.scss";
import TagsInputComponent from "../TagsInput/TagsInput";

const EditCategory = ({
    data,
    setIsModalVisible,
    categories,
    setCategories,
}) => {
    const { TextArea } = Input;
    const [loading, setLoading] = useState(false);
    const CategorySchema = Yup.object().shape({
        name: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Please enter the category name"),
        isActive: Yup.boolean(),
        description: Yup.string()
            .required("Please enter category description")
            .min(2, "Too Short!"),
        // parentId: Yup.string().required("Please select a category option"),
        slug: Yup.string().required("Please enter category slug"),
        menuImage: Yup.array()
            .min(1, "Choose an image")
            .required("Choose an image"),
        webBanner: Yup.array()
            .min(1, "Choose an image")
            .required("Choose an image"),
        mobileBanner: Yup.array()
            .min(1, "Choose an image")
            .required("Choose an image"),
    });

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const handleImageUpload = async (formData, images) => {
        await Promise.all(
            images.map(async ({ name, value }) => {
                await imageAppendHandler(formData, value, name);
            })
        );
        return true;
    };

    const imageAppendHandler = async (data, image, name) => {
        if (image?.originFileObj) {
            await data.append(name, image?.originFileObj);
        } else {
            const url = image.thumbUrl;
            const imgExt = getUrlExtension(url);
            const fileName = `myFile.${imgExt}`;
            let file;
            await fetch(url).then(async (response) => {
                const blob = await response.blob();
                file = new File([blob], fileName, {
                    type: blob.type,
                });
                data.append(name, file);
            });
            return file;
        }
    };

    const categoryEditHandler = async (values) => {
        setLoading(true);
        const formdata = new FormData();
        const images = [
            {
                name: "menu",
                value: values.menuImage[0],
            },
            {
                name: "web",
                value: values.webBanner[0],
            },
            {
                name: "mobile",
                value: values.mobileBanner[0],
            },
        ];
        await handleImageUpload(formdata, images);
        formdata.append("name", values?.name);
        formdata.append("isActive", values?.isActive);
        formdata.append("description", values?.description);
        // values.parentId !== "root" &&
        //     formdata.append("parent", values.parentId);
        formdata.append("keywords", values.tags);
        formdata.append("slug", values.slug);
        formdata.append("isSpecial", values.isSpecial);

        const config = {
            headers: {
                "x-auth-token": token(),
                "Content-Type": "multipart/form-data",
            },
        };

        PrivateAxios.patch(`/categories/${data?.id}`, formdata, config)
            .then(function (response) {
                message.success(response?.data?.message);
                setCategories((prevState) => {
                    let objIndex = prevState.findIndex(
                        (obj) => obj._id === data?.id
                    );
                    if (objIndex > -1) {
                        prevState[objIndex] = response?.data?.data;
                    }
                    return [...prevState];
                });
                setLoading(false);
                setIsModalVisible(null);
            })
            .catch(function (error) {
                setLoading(false);
                message.error(error?.response?.data?.message);
            });
    };

    // const parentCategories = () => [
    //     {
    //         value: "root",
    //         title: "Root",
    //         children:
    //             categories?.length > 0 &&
    //             categories?.map((category) => ({
    //                 value: category._id,
    //                 title: category.name,
    //             })),
    //     },
    // ];

    return (
        <div className={styles.EditCategoryWrapper}>
            <Formik
                initialValues={{
                    name: data?.name,
                    isActive: data?.active,
                    description: data?.description,
                    tags: data?.keywords,
                    slug: data?.slug,
                    menuImage: [
                        {
                            thumbUrl: `${IMAGE_URL}${data?.images?.menu}`,
                        },
                    ],
                    webBanner: [
                        { thumbUrl: `${IMAGE_URL}${data?.images?.web}` },
                    ],
                    mobileBanner: [
                        { thumbUrl: `${IMAGE_URL}${data?.images?.mobile}` },
                    ],
                    isSpecial: data?.isSpecial,
                }}
                validationSchema={CategorySchema}
                onSubmit={categoryEditHandler}
            >
                {({
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    handleReset,
                    setFieldValue,
                    values,
                }) => (
                    <>
                        <Col>
                            <Row align='middle' className='mb-4'>
                                <Col span={8}>
                                    <p className='text-right'>
                                        Category Name{" "}
                                        <span className='text-danger'>*</span>
                                    </p>
                                </Col>
                                <Col span={15}>
                                    <Input
                                        placeholder='Category Name'
                                        onChange={handleChange("name")}
                                        onBlur={handleBlur("name")}
                                        value={values.name}
                                    />
                                    <pre className='text-danger'>
                                        {errors.name && touched.name ? (
                                            <div>{errors.name}</div>
                                        ) : null}
                                    </pre>
                                </Col>
                            </Row>
                            {/* <Row align='middle' className='mb-4'>
                                <Col span={8}>
                                    <p className='text-right'>
                                        Parent category
                                        <span className='text-danger'>*</span>
                                    </p>
                                </Col>
                                <Col span={15}>
                                    <TreeSelect
                                        style={{ width: "100%" }}
                                        treeLine={true}
                                        treeData={parentCategories()}
                                        onSelect={(e) =>
                                            setFieldValue("parentId", e)
                                        }
                                        value={values.parentId}
                                    />
                                </Col>
                            </Row> */}
                            <Row align='middle' className='mb-4'>
                                <Col span={8}>
                                    <p className='text-right mb-0'>
                                        isActive{" "}
                                        <span className='text-danger'>*</span>
                                    </p>
                                </Col>
                                <Col span={15}>
                                    <Switch
                                        defaultChecked
                                        onChange={(e) =>
                                            setFieldValue("isActive", e)
                                        }
                                        checked={values.isActive}
                                    />
                                </Col>
                            </Row>
                            <Row align='top' className='mb-4'>
                                <Col span={8}>
                                    <p className='text-right mb-0'>
                                        Description{" "}
                                        <span className='text-danger'>*</span>
                                    </p>
                                </Col>
                                <Col span={15}>
                                    <TextArea
                                        rows={4}
                                        placeholder='Description'
                                        onChange={handleChange("description")}
                                        onBlur={handleBlur("description")}
                                        value={values.description}
                                    />

                                    <pre className='text-danger'>
                                        {errors.description &&
                                        touched.description ? (
                                            <div>{errors.description}</div>
                                        ) : null}
                                    </pre>
                                </Col>
                            </Row>
                            <Row align='middle' className='mb-4'>
                                <Col span={8}>
                                    <p className='text-right mb-0'>
                                        Category Image{" "}
                                        <span className='text-danger'>*</span>
                                    </p>
                                </Col>
                                <Col span={15}>
                                    <Row>
                                        <Space>
                                            <Col>
                                                <p>Menu Image</p>
                                                <Upload
                                                    listType='picture-card'
                                                    className='avatar-uploader'
                                                    showUploadList={true}
                                                    onChange={(e) => {
                                                        handleBlur("menuImage");
                                                        setFieldValue(
                                                            "menuImage",
                                                            e.fileList
                                                        );
                                                    }}
                                                    beforeUpload={() => false}
                                                    accept='image/*'
                                                    fileList={values.menuImage}
                                                    maxCount={1}
                                                    onRemove={() =>
                                                        setFieldValue(
                                                            "menuImage",
                                                            []
                                                        )
                                                    }
                                                >
                                                    {uploadButton}
                                                </Upload>
                                                {touched.menuImage &&
                                                errors.menuImage ? (
                                                    <pre className='text-danger'>
                                                        {errors.menuImage}
                                                    </pre>
                                                ) : null}
                                            </Col>
                                            <Col>
                                                <p>Web Banner</p>
                                                <Upload
                                                    listType='picture-card'
                                                    className='avatar-uploader'
                                                    showUploadList={true}
                                                    onChange={(e) => {
                                                        handleBlur("webBanner");
                                                        setFieldValue(
                                                            "webBanner",
                                                            e.fileList
                                                        );
                                                    }}
                                                    beforeUpload={() => false}
                                                    accept='image/*'
                                                    fileList={values.webBanner}
                                                    maxCount={1}
                                                    onRemove={() =>
                                                        setFieldValue(
                                                            "webBanner",
                                                            []
                                                        )
                                                    }
                                                >
                                                    {uploadButton}
                                                </Upload>
                                                {touched.webBanner &&
                                                errors.webBanner ? (
                                                    <pre className='text-danger'>
                                                        {errors.webBanner}
                                                    </pre>
                                                ) : null}
                                            </Col>
                                            <Col>
                                                <p>Mobile Banner</p>
                                                <Upload
                                                    listType='picture-card'
                                                    className='avatar-uploader'
                                                    showUploadList={true}
                                                    onChange={(e) => {
                                                        handleBlur(
                                                            "mobileBanner"
                                                        );
                                                        setFieldValue(
                                                            "mobileBanner",
                                                            e.fileList
                                                        );
                                                    }}
                                                    beforeUpload={() => false}
                                                    accept='image/*'
                                                    fileList={
                                                        values.mobileBanner
                                                    }
                                                    maxCount={1}
                                                    onRemove={() =>
                                                        setFieldValue(
                                                            "mobileBanner",
                                                            []
                                                        )
                                                    }
                                                >
                                                    {uploadButton}
                                                </Upload>
                                                {touched.mobileBanner &&
                                                errors.mobileBanner ? (
                                                    <pre className='text-danger'>
                                                        {errors.mobileBanner}
                                                    </pre>
                                                ) : null}
                                            </Col>
                                        </Space>
                                    </Row>
                                </Col>
                            </Row>
                            <Row align='middle' className='mb-4'>
                                <Col span={8}>
                                    <p className='text-right mb-0'>
                                        isSpecial
                                        <span className='text-danger'>*</span>
                                    </p>
                                </Col>
                                <Col span={15}>
                                    <Switch
                                        onChange={(value) => {
                                            setFieldValue("isSpecial", value);
                                        }}
                                        checked={values.isSpecial}
                                    />
                                </Col>
                            </Row>
                            <Row align='middle' className='mb-4'>
                                <Col span={8}>
                                    <p className='text-right mb-0'>
                                        Meta Keywords{" "}
                                        <span className='text-danger'>*</span>
                                    </p>
                                </Col>
                                <Col span={15}>
                                    <TagsInputComponent
                                        tagData={values.tags}
                                        setTagData={setFieldValue}
                                    />
                                    <pre className='text-danger'>
                                        {errors.tags && touched.tags ? (
                                            <div>{errors.tags}</div>
                                        ) : null}
                                    </pre>
                                </Col>
                            </Row>
                            <Row align='middle' className='mb-4'>
                                <Col span={8}>
                                    <p className='text-right mb-0'>
                                        Slug{" "}
                                        <span className='text-danger'>*</span>
                                    </p>
                                </Col>
                                <Col span={15}>
                                    <Input
                                        placeholder='Friendly Slug'
                                        onChange={handleChange("slug")}
                                        onBlur={handleBlur("slug")}
                                        value={values.slug}
                                        className='mt-3'
                                    />
                                    <pre className='text-danger'>
                                        {errors.slug && touched.slug ? (
                                            <div>{errors.slug}</div>
                                        ) : null}
                                    </pre>
                                </Col>
                            </Row>
                        </Col>

                        <Row align='middle' justify='center'>
                            <Space>
                                <Button
                                    icon={<CloseOutlined />}
                                    size='large'
                                    onClick={() => setIsModalVisible(false)}
                                    className='d-flex align-items-center'
                                >
                                    Cancel
                                </Button>

                                <Button
                                    type='primary'
                                    icon={<SaveOutlined />}
                                    size='large'
                                    onClick={handleSubmit}
                                    className='d-flex align-items-center'
                                    loading={loading}
                                >
                                    Save
                                </Button>
                            </Space>
                        </Row>
                    </>
                )}
            </Formik>{" "}
        </div>
    );
};

export default EditCategory;
