// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Topbar_TopbarWrapper__T-GfZ {\n  background-color: #fff;\n  border-bottom: 0.5px solid #fafafc;\n  border-top: 1px solid #fafafc;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 10px 30px;\n  margin-right: -30px;\n}", "",{"version":3,"sources":["webpack://./src/components/TopBar/Topbar.module.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,kCAAA;EACA,6BAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,kBAAA;EACA,mBAAA;AACJ","sourcesContent":[".TopbarWrapper {\n    background-color: #fff;\n    border-bottom: 0.5px solid #fafafc;\n    border-top: 1px solid #fafafc;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 10px 30px;\n    margin-right: -30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TopbarWrapper": "Topbar_TopbarWrapper__T-GfZ"
};
export default ___CSS_LOADER_EXPORT___;
