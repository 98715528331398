/* eslint-disable react/prop-types */

import styles from "./Topbar.module.scss";

const Topbar = ({ LeftComponent, RightComponent }) => {
    // const width = window.screen.width - 232;
    return (
        <div style={{ width: "100%" }} className={styles.TopbarWrapper}>
            {LeftComponent()}
            {RightComponent()}
        </div>
    );
};

export default Topbar;
