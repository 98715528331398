// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddCategory_AddCategoryWrapper__1y-tl .AddCategory_heading__7GYWK {\n  color: #ae674c;\n}\n.AddCategory_AddCategoryWrapper__1y-tl .AddCategory_content__\\+pDzq {\n  padding: 30px;\n}\n.AddCategory_AddCategoryWrapper__1y-tl .AddCategory_content__\\+pDzq .AddCategory_AddCategoryContainer__1Nvts {\n  background-color: white;\n  padding-bottom: 15px;\n}", "",{"version":3,"sources":["webpack://./src/Pages/AddCategory/AddCategory.module.scss"],"names":[],"mappings":"AACI;EACI,cAAA;AAAR;AAGI;EACI,aAAA;AADR;AAEQ;EACI,uBAAA;EACA,oBAAA;AAAZ","sourcesContent":[".AddCategoryWrapper {\n    .heading {\n        color: #ae674c;\n    }\n\n    .content {\n        padding: 30px;\n        .AddCategoryContainer {\n            background-color: white;\n            padding-bottom: 15px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AddCategoryWrapper": "AddCategory_AddCategoryWrapper__1y-tl",
	"heading": "AddCategory_heading__7GYWK",
	"content": "AddCategory_content__+pDzq",
	"AddCategoryContainer": "AddCategory_AddCategoryContainer__1Nvts"
};
export default ___CSS_LOADER_EXPORT___;
