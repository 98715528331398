import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    DeleteFilled,
    EditFilled,
    PlusOutlined,
} from "@ant-design/icons";
import {
    Breadcrumb,
    Button,
    message,
    Popconfirm,
    Row,
    Space,
    Table,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Topbar from "../../components/TopBar";
import PrivateAxios from "../../services/axiosService";
import { token } from "../../utils";
import { IMAGE_URL } from "../../utils/constants";
import styles from "./HomeManagement.module.scss";

const HomeManagement = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [banners, setBanners] = useState([]);

    //FETCH ALL BANNERS
    const fetchBannersHandler = useCallback(() => {
        setIsLoading(true);
        setIsLoading(true);
        let config = {
            headers: {
                "x-auth-token": token(),
            },
        };
        PrivateAxios("/banners", config)
            .then(function (response) {
                setBanners(response?.data);
                setIsLoading(false);
            })
            .catch(function (error) {
                setIsLoading(false);
                message.error(error?.response?.data?.message);
            });
    }, []);

    //DELETE A BOX
    const deleteBannerHandler = (id) => {
        setIsLoading(true);
        let config = {
            headers: {
                "x-auth-token": token(),
            },
        };

        PrivateAxios.delete(`/banners/${id}`, config)
            .then(function (response) {
                // fetchBannersHandler();
                setBanners((prevState) =>
                    prevState.filter((box) => box._id !== id)
                );
                setIsLoading(false);
                message.success(response?.data?.message);
            })
            .catch(function (error) {
                setIsLoading(false);
                message.error(error?.response?.data?.message);
            });
    };

    useEffect(() => {
        fetchBannersHandler();
    }, [fetchBannersHandler]);

    //DATASOURCE FOR BANNER TABLE
    const data =
        banners?.length > 0 &&
        banners.map((item, i) => ({
            key: item?._id,
            id: i + 1,
            image: IMAGE_URL + item?.images?.web,
            link: item?.link,
            isActive: item?.isActive,
            ...item,
        }));

    //COLUMN DETAILS FOR BANNER TABLE
    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "_id",
            render: (text) => <p className='text-center'>{text}</p>,
            align: "center",
        },
        {
            title: "Banner",
            dataIndex: "image",
            key: "image",
            render: (src) => (
                <img src={src} alt='banner-img' className={styles.bannerImg} />
            ),
            align: "center",
        },
        {
            title: "Link",
            dataIndex: "link",
            key: "link",
            render: (data) => (
                <Button
                    type='link'
                    className={styles.linkButton}
                    target='_blank'
                    href={data}
                >
                    Link
                </Button>
            ),
            align: "center",
        },
        {
            title: "Visibility",
            key: "isActive",
            dataIndex: "isActive",
            render: (isActive) =>
                isActive ? (
                    <CheckCircleOutlined className='text-success fs-6' />
                ) : (
                    <CloseCircleOutlined className='text-danger fs-6' />
                ),
            align: "center",
        },
        {
            title: "Edit",
            key: "edit",
            align: "center",
            render: (item) => (
                <Button
                    shape='circle'
                    type='text'
                    icon={<EditFilled />}
                    onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/edit-banner/${item._id}`, {
                            state: { data: item, title: "Edit Banner" },
                        });
                    }}
                />
            ),
        },
        {
            title: "Delete",
            key: "delete",
            align: "center",
            render: (item) => (
                <Popconfirm
                    title='Are you sure to delete this banner?'
                    onConfirm={(e) => {
                        e.stopPropagation();
                        deleteBannerHandler(item?._id);
                    }}
                    onCancel={(e) => {
                        e.stopPropagation();
                    }}
                    okText='Yes'
                    cancelText='No'
                >
                    <Button
                        shape='circle'
                        type='text'
                        icon={<DeleteFilled />}
                        className='text-danger'
                    />
                </Popconfirm>
            ),
        },
    ];

    const LeftComponent = () => (
        <div>
            <Breadcrumb separator='>'>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Banners</Breadcrumb.Item>
            </Breadcrumb>
            <h3 className={styles.heading}>Banners</h3>
        </div>
    );

    const RightComponent = () => (
        <Row>
            <Space>
                <Button
                    type='primary'
                    // disabled
                    icon={<PlusOutlined />}
                    size='large'
                    className={styles.btn}
                    onClick={() =>
                        navigate("/add-banner", {
                            state: { title: "Add Banner" },
                        })
                    }
                >
                    Add Banner
                </Button>
            </Space>
        </Row>
    );

    return (
        <div className={styles.homeWrapper}>
            <Topbar
                LeftComponent={LeftComponent}
                RightComponent={RightComponent}
            />

            <div className={styles.homeContainer}>
                <Table
                    columns={columns}
                    dataSource={data}
                    loading={isLoading}
                />
            </div>
        </div>
    );
};

export default HomeManagement;
