import { Breadcrumb, Button, message, Pagination, Table, Tabs } from "antd";
import { useCallback, useEffect, useReducer, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { token } from "../../utils";
import Topbar from "../../components/TopBar";
import PrivateAxios from "../../services/axiosService";
import styles from "./Orders.module.scss";
import {
    ACTION_TYPES,
    INITIAL_STATE,
    orderReducer,
} from "../../reducer/orderReducer";

const Orders = () => {
    const navigate = useNavigate();
    const [orderState, orderDispatch] = useReducer(orderReducer, INITIAL_STATE);
    const [breadcrumb, setBreadcrumb] = useState("All Orders");

    //PAGE HEADER LEFT
    const LeftComponent = () => (
        <div>
            <Breadcrumb separator='>'>
                <Breadcrumb.Item>Orders</Breadcrumb.Item>
                <Breadcrumb.Item>{breadcrumb}</Breadcrumb.Item>
            </Breadcrumb>
            <h3 className={styles.heading}>{breadcrumb}</h3>
        </div>
    );

    //PAGE HEADER RIGHT
    const RightComponent = () => <div />;

    //DATASOURCE FOR ORDERS TABLE
    const dataSource =
        orderState?.orders?.length > 0 &&
        orderState?.orders?.map((item) => ({
            key: item?._id,
            orderid: item?.orderId,
            products: item?.orderItems?.length,
            time: moment(item?.createdAt)?.format("DD/MM/YY, hh:mm A"),
            buyer: item?.user?.fullName || item?.user?.mobileNumber,
            price: `AED ${item?.subTotal?.toFixed(2)}`,
            delivery: item?.deliveryType === "address" ? "Ship" : "Pickup",
            ...item,
        }));

    //COLUMNS FOR ORDERS TABLE
    const columns = [
        {
            title: "Order ID",
            key: "orderid",
            dataIndex: "orderId",
            align: "center",
        },
        {
            title: "Products",
            dataIndex: "products",
            key: "products",
            align: "center",
        },
        {
            title: "Date & Time",
            dataIndex: "time",
            key: "time",
            align: "center",
        },
        {
            title: "Buyer",
            dataIndex: "buyer",
            key: "buyer",
            align: "center",
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
            align: "center",
        },
        {
            title: "Delivery Type",
            dataIndex: "delivery",
            key: "delivery",
            align: "center",
        },
        {
            title: "View",
            key: "view",
            render: (data) => (
                <Button
                    type='ghost'
                    className='border'
                    onClick={() => navigate(`/order/${data?.key}`)}
                >
                    View
                </Button>
            ),
            align: "center",
        },
    ];

    //FETCH ORDERS BASED ON ORDER STATUS
    const fetchOrdersHandler = useCallback((key, pageNo, pageSize) => {
        orderDispatch({ type: ACTION_TYPES.FETCH_START });
        let pageNumber = pageNo ? pageNo : 1;
        const size = pageSize ? pageSize : 10;
        const orderStatus = key ? `orderStatus=${key}` : null;

        const config = {
            headers: {
                "x-auth-token": token(),
            },
        };

        PrivateAxios.get(
            `/orders?size=${size}&pageNo=${pageNumber}&${orderStatus}`,
            config
        )
            .then(function (response) {
                orderDispatch({
                    type: ACTION_TYPES.FETCH_SUCCESS,
                    payload: {
                        currentPage: response?.data?.currentPage || 1,
                        orders: response?.data?.data,
                        totalOrders: parseInt(response?.data?.totalItems),
                    },
                });
            })
            .catch(function (error) {
                orderDispatch({ type: ACTION_TYPES.FETCH_ERROR });
                message.error(
                    error?.response?.data?.message || "Something went wrong!"
                );
            });
    }, []);

    useEffect(() => {
        fetchOrdersHandler("");
    }, [fetchOrdersHandler]);

    //CONTENT FOR ORDER STATUS TABS
    const tabContent = (orderStatus) => (
        <>
            <div className='py-1 mt-1'>
                <Table
                    pagination={false}
                    dataSource={dataSource}
                    columns={columns}
                    loading={orderState.loading}
                />
            </div>
            <div className='d-flex justify-content-end'>
                <Pagination
                    current={orderState.currentPage}
                    onChange={(pageNo, pageSize) => {
                        fetchOrdersHandler(orderStatus, pageNo, pageSize);
                    }}
                    total={orderState.totalOrders}
                    defaultPageSize={10}
                    showSizeChanger={true}
                    pageSizeOptions={["10", "15", "20", "25"]}
                />
            </div>
        </>
    );

    //ITEMS FOR ORDER STATUS TABS
    const tabItems = [
        {
            label: "All Orders",
            key: "",
            children: tabContent(""),
        },
        {
            label: "New Orders",
            key: "placed",
            children: tabContent("placed"),
        },
        {
            label: "Processing Orders",
            key: "processing",
            children: tabContent("processing"),
        },
        {
            label: "Delivered Orders",
            key: "delivered",
            children: tabContent("delivered"),
        },
        {
            label: "Cancelled Orders",
            key: "cancelled",
            children: tabContent("cancelled"),
        },
    ];

    return (
        <div className={styles.OrdersWrapper}>
            <Topbar
                LeftComponent={LeftComponent}
                RightComponent={RightComponent}
            />
            <div className={styles.ordersContainer}>
                <Tabs
                    defaultActiveKey=''
                    items={tabItems}
                    onChange={(key) => {
                        fetchOrdersHandler(key);
                        key === ""
                            ? setBreadcrumb("All Orders")
                            : key === "placed"
                            ? setBreadcrumb("New Orders")
                            : key === "processing"
                            ? setBreadcrumb("Processing Orders")
                            : key === "delivered"
                            ? setBreadcrumb("Delivered Orders")
                            : key === "cancelled"
                            ? setBreadcrumb("Cancelled Orders")
                            : setBreadcrumb("All Orders");
                    }}
                />
            </div>
        </div>
    );
};

export default Orders;
