export const ACTION_TYPES = {
    FETCH_PRODUCTS_START: "FETCH_PRODUCTS_START",
    FETCH_PRODUCTS_SUCCESS: "FETCH_PRODUCTS_SUCCESS",
    FETCH_PRODUCTS_ERROR: "FETCH_PRODUCTS_ERROR",
    DELETE_PRODUCTS_START: "DELETE_PRODUCTS_START",
    DELETE_PRODUCTS_SUCCESS: "DELETE_PRODUCTS_SUCCESS",
    DELETE_PRODUCTS_ERROR: "DELETE_PRODUCTS_ERROR",
    FETCH_CATEGORIES_START: "FETCH_CATEGORIES_START",
    FETCH_CATEGORIES_SUCCESS: "FETCH_CATEGORIES_SUCCESS",
    FETCH_CATEGORIES_ERROR: "FETCH_CATEGORIES_ERROR",
};

export const INITIAL_STATE = {
    loading: false,
    products: [],
    totalProducts: 0,
    categories: [],
    currentPage: 1,
};

export const productReducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_PRODUCTS_START:
            return {
                ...state,
                loading: true,
                products: [],
                totalProducts: 0,
                currentPage: 1,
            };

        case ACTION_TYPES.FETCH_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                products: action.payload.products,
                totalProducts: action.payload.totalProducts,
                currentPage: action.payload.currentPage,
            };

        case ACTION_TYPES.FETCH_PRODUCTS_ERROR:
            return {
                ...state,
                loading: false,
                products: [],
                totalProducts: 0,
                currentPage: 1,
            };

        case ACTION_TYPES.FETCH_CATEGORIES_START:
            return {
                ...state,
                loading: true,
                categories: [],
                currentPage: 1,
            };

        case ACTION_TYPES.FETCH_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                categories: action.payload.categories,
            };

        case ACTION_TYPES.FETCH_CATEGORIES_ERROR:
            return {
                ...state,
                loading: false,
                categories: [],
                currentPage: 1,
            };

        case ACTION_TYPES.DELETE_PRODUCTS_START:
            return {
                ...state,
                loading: true,
            };
        case ACTION_TYPES.DELETE_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                products: state.products.filter(
                    (product) => product._id !== action.payload.id
                ),
            };
        case ACTION_TYPES.DELETE_PRODUCTS_ERROR:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};
