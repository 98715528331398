// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Products_ProductWrapper__axbVu thead[class*=ant-table-thead] th {\n  background-color: #fff !important;\n}\n.Products_ProductWrapper__axbVu button[class*=ant-dropdown-trigger] {\n  border-top-left-radius: 0;\n  border-bottom-left-radius: 0;\n}\n.Products_ProductWrapper__axbVu .Products_heading__Rulrw {\n  color: #ae674c;\n}\n.Products_ProductWrapper__axbVu .Products_productContainer__CZunu {\n  padding: 30px;\n  padding-top: 0;\n}\n.Products_ProductWrapper__axbVu .Products_productContainer__CZunu .Products_productImg__fDH7r {\n  height: 80px;\n  width: 80px;\n  object-fit: contain;\n}\n.Products_ProductWrapper__axbVu .Products_productContainer__CZunu .Products_btn__rWVwt {\n  background-color: #d02130;\n  border-color: #d02130;\n  border-radius: 5px;\n  display: flex;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/Pages/Products/Products.module.scss"],"names":[],"mappings":"AACI;EACI,iCAAA;AAAR;AAGI;EACI,yBAAA;EACA,4BAAA;AADR;AAII;EACI,cAAA;AAFR;AAKI;EACI,aAAA;EACA,cAAA;AAHR;AAKQ;EACI,YAAA;EACA,WAAA;EACA,mBAAA;AAHZ;AAMQ;EACI,yBAAA;EACA,qBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;AAJZ","sourcesContent":[".ProductWrapper {\n    thead[class*=\"ant-table-thead\"] th {\n        background-color: #fff !important;\n    }\n\n    button[class*=\"ant-dropdown-trigger\"] {\n        border-top-left-radius: 0;\n        border-bottom-left-radius: 0;\n    }\n\n    .heading {\n        color: #ae674c;\n    }\n\n    .productContainer {\n        padding: 30px;\n        padding-top: 0;\n\n        .productImg {\n            height: 80px;\n            width: 80px;\n            object-fit: contain;\n        }\n\n        .btn {\n            background-color: #d02130;\n            border-color: #d02130;\n            border-radius: 5px;\n            display: flex;\n            align-items: center;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ProductWrapper": "Products_ProductWrapper__axbVu",
	"heading": "Products_heading__Rulrw",
	"productContainer": "Products_productContainer__CZunu",
	"productImg": "Products_productImg__fDH7r",
	"btn": "Products_btn__rWVwt"
};
export default ___CSS_LOADER_EXPORT___;
