import React, { useEffect, useState } from "react";
import {
    DownOutlined,
    ExclamationCircleOutlined,
    LogoutOutlined,
} from "@ant-design/icons";
import { Button, Col, Dropdown, Layout, Menu, Modal, Row, Space } from "antd";
import CatalogSvg from "../../assets/icons/catalog.svg";
import CatalogActiveSvg from "../../assets/icons/catalog-active.svg";
import SalesSvg from "../../assets/icons/sales.svg";
import SalesActiveSvg from "../../assets/icons/sales-active.svg";
import ReportSvg from "../../assets/icons/report.svg";
import ReportActiveSvg from "../../assets/icons/report-active.svg";
import SettingsSvg from "../../assets/icons/settings.svg";
import SettingsActiveSvg from "../../assets/icons/settings-active.svg";
import Logo from "../../assets/images/logo.webp";
import { Outlet, useNavigate } from "react-router-dom";
import styles from "./Layout.module.scss";

const { Header, Content, Sider } = Layout;

const Wrapper = () => {
    const navigate = useNavigate();
    const pathArray = window.location.pathname.split("/");
    const pathname = "/" + pathArray[1];
    const [collapsed, setCollapsed] = useState(false);

    window.addEventListener("storage", () => {
        navigate("/login");
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleLogout = () => {
        Modal.confirm({
            title: "Logout",
            icon: <ExclamationCircleOutlined />,
            content: "Are you sure you want logout?",
            okText: "Ok",
            cancelText: "Cancel",
            onOk: () => {
                localStorage.clear();
                navigate("/login");
            },
        });
    };

    const selectedKey =
        pathname === "/"
            ? "orders"
            : pathname === "/products" ||
              pathname === "/add-product" ||
              pathname === "/edit-product"
            ? "products"
            : pathname === "/categories" || pathname === "/add-category"
            ? "categories"
            : pathname === "/home-management" ||
              pathname === "/add-banner" ||
              pathname === "/edit-banner"
            ? "homeManagement"
            : pathname === "/delivery-charges" ||
              pathname === "/add-delivery-charge"
            ? "deliveryCharge"
            : pathname === "/emirates"
            ? "emirates"
            : "orders";

    const isOrder = pathname === "/" || pathname === "/order";

    const isCatalog =
        pathname === "/products" ||
        pathname === "/add-product" ||
        pathname === "/edit-product" ||
        pathname === "/categories" ||
        pathname === "/add-category";

    const isPageManagement =
        pathname === "/home-management" ||
        pathname === "/add-banner" ||
        pathname === "/edit-banner";

    const isSettings =
        pathname === "/delivery-charges" ||
        pathname === "/add-delivery-charge" ||
        pathname === "/edit-delivery-charge" ||
        pathname === "/emirates";

    //CREATE SIDEBAR MENU ITEMS
    const getMenuItem = (label, key, icon, children) => {
        return {
            label,
            key,
            icon,
            children,
        };
    };

    //SIDEBAR MENU ITEMS
    const SideBarMenuItems = [
        getMenuItem(
            "Orders",
            "orders",
            isOrder ? (
                <img alt='' src={SalesActiveSvg} />
            ) : (
                <img alt='' src={SalesSvg} />
            )
        ),
        getMenuItem(
            "Catalog",
            "catalog",
            isCatalog ? (
                <img alt='' src={CatalogActiveSvg} />
            ) : (
                <img alt='' src={CatalogSvg} />
            ),
            [
                getMenuItem("Products", "products"),
                getMenuItem("Categories", "categories"),
            ]
        ),
        getMenuItem(
            "Page Management",
            "pageManagement",
            isPageManagement ? (
                <img alt='' src={ReportActiveSvg} />
            ) : (
                <img alt='' src={ReportSvg} />
            ),
            [getMenuItem("Home Page", "homeManagement")]
        ),
        getMenuItem(
            "Configurations",
            "settings",
            isSettings ? (
                <img alt='' src={SettingsActiveSvg} />
            ) : (
                <img alt='' src={SettingsSvg} />
            ),
            [
                getMenuItem("Emirates", "emirates"),
                getMenuItem("Delivery Charges", "deliveryCharge"),
            ]
        ),
    ];

    const handleChangeMenu = (event) => {
        switch (event.key) {
            case "orders":
                navigate("/");
                break;

            case "products":
                navigate("/products");
                break;

            case "categories":
                navigate("/categories");
                break;

            case "homeManagement":
                navigate("/home-management");
                break;

            case "emirates":
                navigate("/emirates");
                break;

            case "deliveryCharge":
                navigate("/delivery-charge");
                break;

            default:
                navigate("/");
                break;
        }
    };

    const items = [
        { label: "Orders", key: "orders" }, // remember to pass the key prop
        { label: "Products", key: "products" },
        { label: "Variants", key: "variants" },
        { label: "Categories", key: "categories" },
        { label: "Home Page", key: "homeManagement" },
        { label: "Emirates", key: "emirates" },
        { label: "Delivery Charges", key: "deliveryCharge" },
    ];

    const menuProps = {
        items,
        onClick: handleChangeMenu,
    };

    return (
        <Layout
            style={{
                height: "100vh",
                overflow: "hidden",
            }}
            className={styles.LayoutWrapper}
        >
            <Sider
                className={styles.sidebar}
                theme='light'
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
            >
                <div className={styles.logoWrapper}>
                    <img src={Logo} alt='logo' className={styles.logo} />
                </div>
                <Menu
                    onClick={handleChangeMenu}
                    theme='light'
                    defaultOpenKeys={
                        isOrder
                            ? ["orders"]
                            : isCatalog
                            ? ["catalog"]
                            : isPageManagement
                            ? ["pageManagement"]
                            : isSettings
                            ? ["settings"]
                            : ["orders"]
                    }
                    defaultSelectedKeys={[selectedKey]}
                    mode='inline'
                    items={SideBarMenuItems}
                />
            </Sider>
            <Layout className='site-layout'>
                <Header
                    className={styles.header}
                    style={{
                        padding: 0,
                    }}
                >
                    <Row className='h-100'>
                        <Col span={8} />
                        <Col
                            span={8}
                            className='d-flex justify-content-center align-items-center'
                        >
                            <Dropdown menu={menuProps}>
                                <Button>
                                    <Space>
                                        Quick Access
                                        <DownOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                        </Col>
                        <Col
                            span={8}
                            className='pe-5 d-flex justify-content-end align-items-center'
                        >
                            <Button
                                shape='circle'
                                className='text-danger'
                                onClick={handleLogout}
                                icon={<LogoutOutlined />}
                            />
                        </Col>
                    </Row>
                </Header>
                <Content
                    style={{ overflowY: "scroll", backgroundColor: "#fafafa" }}
                >
                    <Outlet />
                </Content>
                {/* <Footer
                    style={{
                        textAlign: "center",
                    }}
                >
                    Golden Ribbon ©2022 Created by Zoftcares-Solutions
                </Footer> */}
            </Layout>
        </Layout>
    );
};
export default Wrapper;
