// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddDeliveryCharge_AddDeliveryChargeWrapper__p0rTl .AddDeliveryCharge_heading__3mMBx {\n  color: #ae674c;\n}\n.AddDeliveryCharge_AddDeliveryChargeWrapper__p0rTl .AddDeliveryCharge_content__6IG9k {\n  padding: 30px;\n}\n.AddDeliveryCharge_AddDeliveryChargeWrapper__p0rTl .AddDeliveryCharge_content__6IG9k .AddDeliveryCharge_addDeliveryChargeContainer__GgJxh {\n  background-color: white;\n  padding-bottom: 15px;\n}", "",{"version":3,"sources":["webpack://./src/Pages/AddDeliveryCharge/AddDeliveryCharge.module.scss"],"names":[],"mappings":"AACI;EACI,cAAA;AAAR;AAGI;EACI,aAAA;AADR;AAEQ;EACI,uBAAA;EACA,oBAAA;AAAZ","sourcesContent":[".AddDeliveryChargeWrapper {\n    .heading {\n        color: #ae674c;\n    }\n\n    .content {\n        padding: 30px;\n        .addDeliveryChargeContainer {\n            background-color: white;\n            padding-bottom: 15px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AddDeliveryChargeWrapper": "AddDeliveryCharge_AddDeliveryChargeWrapper__p0rTl",
	"heading": "AddDeliveryCharge_heading__3mMBx",
	"content": "AddDeliveryCharge_content__6IG9k",
	"addDeliveryChargeContainer": "AddDeliveryCharge_addDeliveryChargeContainer__GgJxh"
};
export default ___CSS_LOADER_EXPORT___;
