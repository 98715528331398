import { Tag } from "antd";
import React from "react";
import styles from "./TagsInputComponent.module.scss";

const TagsInputComponent = ({ tagData, setTagData }) => {
    //META KEYWORDS ADD HANDLER
    const addTagData = (event) => {
        if (event.target.value !== "") {
            setTagData("tags", [...tagData, event.target.value]);
            event.target.value = "";
        }
    };

    //DELETE TAGS HADLER
    const deleteTagsHandler = (key) => {
        setTagData(
            "tags",
            tagData.filter((tag, index) => index !== key)
        );
    };

    return (
        <div className={`border px-1 mt-2  ${styles.tagsInputContainer}`}>
            {tagData?.map((tag, index) => (
                <Tag
                    key={index}
                    className='my-1 d-flex justify-content-center align-items-center rounded'
                    closable
                    onClose={() => deleteTagsHandler(index)}
                >
                    {tag}
                </Tag>
            ))}
            <input
                className='my-1'
                placeholder='Use enter key to create tags'
                onKeyPress={(event) => {
                    event.key === "Enter" && event.preventDefault();
                }}
                onKeyUp={(event) => {
                    event.key === "Enter" && event.preventDefault();
                    return event.key === "Enter" ? addTagData(event) : null;
                }}
            />
        </div>
    );
};

export default TagsInputComponent;
