import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    DeleteFilled,
    EditFilled,
    PlusOutlined,
} from "@ant-design/icons";
import {
    Breadcrumb,
    Button,
    Divider,
    message,
    Modal,
    Popconfirm,
    Typography,
} from "antd";
import { Space, Table } from "antd";
import axios from "axios";
import Topbar from "../../components/TopBar";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { token } from "../../utils/index";
import { BASE_URL, IMAGE_URL } from "../../utils/constants";

import styles from "./Categories.module.scss";
import EditCategory from "../../components/EditCategory/EditCategory";
import PrivateAxios from "../../services/axiosService";

function Categories() {
    const navigate = useNavigate();
    const [modalData, setModalData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);

    const showModal = (data) => {
        setModalData(data);
    };

    //FETCH ALL CATEGORIES
    const fetchCategoryHandler = useCallback(() => {
        setLoading(true);
        let config = {
            headers: {
                "x-auth-token": token(),
            },
        };
        PrivateAxios.get("/categories?filter=all", config)
            .then(function (response) {
                setLoading(false);
                setCategories(response?.data);
            })
            .catch(function (error) {
                setLoading(false);
                message.error(error?.response?.data?.message);
            });
    }, []);

    //DELETE A CATEGORY
    const deleteCategoryHandler = (id) => {
        setLoading(true);
        let config = {
            method: "delete",
            url: `${BASE_URL}/categories/${id}`,
            headers: {
                "x-auth-token": token(),
            },
        };

        axios(config)
            .then(function (response) {
                setLoading(false);
                message.success(response?.data?.message);
                fetchCategoryHandler();
            })
            .catch(function (error) {
                setLoading(false);
                message.error(error?.response?.data?.message);
            });
    };

    useEffect(() => {
        fetchCategoryHandler();
    }, [fetchCategoryHandler]);

    //DATA SOURCE FOR CATEGORY TABLE
    const dataSource =
        categories.length > 0 &&
        categories.map((item, i) => ({
            index: i + 1,
            key: item?._id,
            id: item?._id,
            name: item?.name,
            description: item?.description,
            active: item?.isActive,
            image: IMAGE_URL + item?.images?.menu,
            ...item,
        }));

    //COLUMN DEFINITIONS FOR CATEGORY TABLE
    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (text) => <span>{text}</span>,
            align: "center",
        },
        {
            title: "Category Name",
            dataIndex: "name",
            key: "name",
            align: "center",
        },
        {
            title: "Image",
            dataIndex: "image",
            key: "image",
            align: "center",
            render: (src) => (
                <img src={src} alt='thumbnail' className={styles.productImg} />
            ),
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            render: (description) => (
                <Typography.Text style={{ width: 200 }} ellipsis={true}>
                    {description}
                </Typography.Text>
            ),
            align: "center",
        },
        {
            title: "Visibility",
            key: "active",
            align: "center",
            render: (status) =>
                status?.active ? (
                    <CheckCircleOutlined className='text-success fs-6' />
                ) : (
                    <CloseCircleOutlined className='text-danger fs-6' />
                ),
        },
        {
            title: "Edit",
            key: "edit",
            align: "center",
            render: (data) => (
                <Button
                    shape='circle'
                    type='text'
                    onClick={(e) => {
                        e?.stopPropagation();
                        showModal(data);
                    }}
                    icon={<EditFilled />}
                />
            ),
        },

        {
            title: "Delete",
            key: "delete",
            align: "center",
            render: (item) => (
                <Space size='middle' onClick={(e) => e?.stopPropagation()}>
                    <Popconfirm
                        onClick={(e) => e?.stopPropagation()}
                        title='Are you sure to delete this Category?'
                        onConfirm={() => {
                            deleteCategoryHandler(item?.id);
                        }}
                        onCancel={() => {}}
                        okText='Yes'
                        cancelText='No'
                    >
                        <Button
                            shape='circle'
                            type='text'
                            className='text-danger'
                            onClick={(e) => e?.stopPropagation()}
                            icon={<DeleteFilled />}
                        />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    //LEFT COMPONENT
    const LeftComponent = () => (
        <div>
            <Breadcrumb separator='>'>
                <Breadcrumb.Item>Catalog</Breadcrumb.Item>
                <Breadcrumb.Item>Categories</Breadcrumb.Item>
            </Breadcrumb>
            <h3 className={styles.heading}>Categories</h3>
        </div>
    );

    //RIGHT COMPONENT
    const RightComponent = () => (
        <Button
            type='primary'
            icon={<PlusOutlined />}
            size='large'
            onClick={() => navigate("/add-category")}
        >
            Add Category
        </Button>
    );

    return (
        <div className={styles.CategoryWrapper}>
            <Topbar
                LeftComponent={LeftComponent}
                RightComponent={RightComponent}
            />
            <div className={styles.categoryContainer}>
                <div className={styles.content}>
                    <div>
                        <h6 className='my-3  text-success'>
                            Total Categories ( {dataSource?.length || 0} )
                        </h6>
                    </div>
                    <Table
                        loading={loading}
                        columns={columns}
                        dataSource={dataSource}
                        pagination={false}
                    />
                </div>
                <Modal
                    footer={null}
                    title={
                        <>
                            <h5 style={{ color: "var(--primary-color)" }}>
                                Edit Category
                            </h5>
                            <Divider />
                        </>
                    }
                    open={modalData !== null}
                    setModalData={setModalData}
                    onCancel={() => setModalData(null)}
                    destroyOnClose={true}
                    width={"80%"}
                >
                    <EditCategory
                        data={modalData}
                        setModalData={setModalData}
                        fetchCategoryHandler={fetchCategoryHandler}
                        setIsModalVisible={() => setModalData(null)}
                        categories={categories}
                        setCategories={setCategories}
                    />
                </Modal>
            </div>
        </div>
    );
}

export default Categories;
