import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import {
    Breadcrumb,
    Button,
    Col,
    Input,
    message,
    Row,
    Select,
    Space,
    Switch,
} from "antd";
import Topbar from "../../components/TopBar";
import { Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { token } from "../../utils";
import * as Yup from "yup";

import styles from "./AddDeliveryCharge.module.scss";
import { useNavigate } from "react-router-dom";
import PrivateAxios from "../../services/axiosService";

function AddDeliveryCharge() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [emirates, setEmirates] = useState([]);

    const CategorySchema = Yup.object().shape({
        emirate: Yup.string().required("Please choose an emirate"),
        defaultDeliveryCharge: Yup.number()
            .min(0, "Default delivery charge mustbe greater than or equal to 0")
            .required("Please enter a default delivery charge"),
        minimumOrder: Yup.number().required(
            "Please enter a minimum order amount"
        ),
        deliveryCharge: Yup.number()
            .min(0, "Delivery charge mustbe greater than or equal to 0")
            .required("Please enter a minimum charge"),
        deliveryAvailability: Yup.boolean(),
    });

    //FETCH ALL CATEGORIES
    const fetchEmiratesHandler = useCallback(() => {
        let config = {
            headers: {
                "x-auth-token": token(),
            },
        };
        PrivateAxios.get("/emirates", config)
            .then(function (response) {
                setLoading(false);
                setEmirates(response?.data);
            })
            .catch(function (error) {
                setLoading(false);
                message.error(error?.response?.data?.message);
            });
    }, []);

    useEffect(() => {
        fetchEmiratesHandler();
    }, [fetchEmiratesHandler]);

    const LeftComponent = () => (
        <div>
            <Breadcrumb separator='>'>
                <Breadcrumb.Item>Settings</Breadcrumb.Item>
                <Breadcrumb.Item href='/delivery-charges'>
                    DeliveryCharges
                </Breadcrumb.Item>
                <Breadcrumb.Item> Add Delivery Charge</Breadcrumb.Item>
            </Breadcrumb>
            <h3 className={styles.heading}>Add Delivery Charge</h3>
        </div>
    );

    const RightComponent = () => <div />;

    return (
        <div className={styles.AddDeliveryChargeWrapper}>
            <Topbar
                LeftComponent={LeftComponent}
                RightComponent={RightComponent}
            />

            <div className={styles.content}>
                <Formik
                    initialValues={{
                        emirate: "",
                        defaultDeliveryCharge: "",
                        minimumOrder: 1,
                        deliveryCharge: "",
                        deliveryAvailability: true,
                    }}
                    validationSchema={CategorySchema}
                    onSubmit={(values) => {
                        setLoading(true);
                        const data = new FormData();
                        data.append("emirate", values.emirate);
                        data.append(
                            "defaultDeliveryCharge",
                            values.defaultDeliveryCharge
                        );
                        data.append("minimumOrder", values.minimumOrder);
                        data.append("deliveryCharge", values.deliveryCharge);
                        data.append(
                            "deliveryAvailability",
                            values.deliveryAvailability
                        );
                        const config = {
                            headers: {
                                "x-auth-token": token(),
                                "Content-Type": "multipart/form-data",
                            },
                        };
                        PrivateAxios.post("/delivery-charges", data, config)
                            .then(function (response) {
                                setLoading(false);
                                message.success(response?.data?.message);
                                navigate("/delivery-charges");
                            })
                            .catch(function (error) {
                                setLoading(false);
                                message.error(error?.response?.data?.message);
                            });
                    }}
                >
                    {({
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                        handleReset,
                        setFieldValue,
                        values,
                    }) => (
                        <div className={styles.addDeliveryChargeContainer}>
                            <Col className='p-3'>
                                <Row
                                    align='middle'
                                    justify='center'
                                    className='mb-4'
                                >
                                    <Col span={4}>
                                        <p className='text-right mb-0'>
                                            Emirate
                                            <span className='text-danger'>
                                                *
                                            </span>
                                        </p>
                                    </Col>
                                    <Col span={8}>
                                        <Select
                                            style={{
                                                width: "100%",
                                            }}
                                            className='mt-3'
                                            onChange={handleChange("emirate")}
                                            onBlur={handleBlur("emirate")}
                                            value={values.emirate}
                                            options={
                                                emirates?.length &&
                                                emirates?.map((emirate) => ({
                                                    value: emirate?._id,
                                                    label: emirate?.name,
                                                }))
                                            }
                                        />
                                        <pre className='text-danger'>
                                            {errors.emirate &&
                                            touched.emirate ? (
                                                <div>{errors.emirate}</div>
                                            ) : null}
                                        </pre>
                                    </Col>
                                </Row>
                                <Row
                                    align='middle'
                                    justify='center'
                                    className='mb-2'
                                >
                                    <Col span={4}>
                                        <p className='text-right mb-0'>
                                            Default delivery charge
                                            <span className='text-danger'>
                                                *
                                            </span>
                                        </p>
                                    </Col>
                                    <Col span={8}>
                                        <Input
                                            min={0}
                                            placeholder='Enter default delivery charge'
                                            onChange={handleChange(
                                                "defaultDeliveryCharge"
                                            )}
                                            onBlur={handleBlur(
                                                "defaultDeliveryCharge"
                                            )}
                                            value={values.defaultDeliveryCharge}
                                            className='mt-3'
                                            type='number'
                                        />
                                        <span
                                            style={{
                                                fontSize: "12px",
                                                color: "#bdbdbd",
                                            }}
                                        >
                                            ( Will be applied when order amount
                                            below minimum order )
                                        </span>
                                        <pre className='text-danger'>
                                            {errors.defaultDeliveryCharge &&
                                            touched.defaultDeliveryCharge ? (
                                                <div>
                                                    {
                                                        errors.defaultDeliveryCharge
                                                    }
                                                </div>
                                            ) : null}
                                        </pre>
                                    </Col>
                                </Row>
                                <Row
                                    align='middle'
                                    justify='center'
                                    className='mb-2'
                                >
                                    <Col span={4}>
                                        <p className='text-right mb-0'>
                                            Minimum Order
                                            <span className='text-danger'>
                                                *
                                            </span>
                                        </p>
                                    </Col>
                                    <Col span={8}>
                                        <Input
                                            min={1}
                                            placeholder='Enter minimum order amount'
                                            onChange={handleChange(
                                                "minimumOrder"
                                            )}
                                            onBlur={handleBlur("minimumOrder")}
                                            value={values.minimumOrder}
                                            className='mt-3'
                                            type='number'
                                        />
                                        <pre className='text-danger'>
                                            {errors.minimumOrder &&
                                            touched.minimumOrder ? (
                                                <div>{errors.minimumOrder}</div>
                                            ) : null}
                                        </pre>
                                    </Col>
                                </Row>
                                <Row
                                    align='middle'
                                    justify='center'
                                    className='mb-2'
                                >
                                    <Col span={4}>
                                        <p className='text-right mb-0'>
                                            Delivery Charge
                                            <span className='text-danger'>
                                                *
                                            </span>
                                        </p>
                                    </Col>
                                    <Col span={8}>
                                        <Input
                                            min={0}
                                            placeholder='Enter delivery charge'
                                            onChange={handleChange(
                                                "deliveryCharge"
                                            )}
                                            onBlur={handleBlur(
                                                "deliveryCharge"
                                            )}
                                            value={values.deliveryCharge}
                                            className='mt-3'
                                            type='number'
                                        />
                                        <span
                                            style={{
                                                fontSize: "12px",
                                                color: "#bdbdbd",
                                            }}
                                        >
                                            ( Will be applied when order amount
                                            above minimum order )
                                        </span>
                                        <pre className='text-danger'>
                                            {errors.deliveryCharge &&
                                            touched.deliveryCharge ? (
                                                <div>
                                                    {errors.deliveryCharge}
                                                </div>
                                            ) : null}
                                        </pre>
                                    </Col>
                                </Row>
                                <Row
                                    align='middle'
                                    justify='center'
                                    className='mb-4'
                                >
                                    <Col span={4}>
                                        <p className='text-right mb-0'>
                                            Delivery Availability
                                            <span className='text-danger'>
                                                *
                                            </span>
                                        </p>
                                    </Col>
                                    <Col span={8}>
                                        <Switch
                                            onChange={(value) => {
                                                setFieldValue(
                                                    "deliveryAvailability",
                                                    value
                                                );
                                            }}
                                            checked={
                                                values.deliveryAvailability
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Row align='middle' justify='center'>
                                <Space>
                                    <Button
                                        icon={<CloseOutlined />}
                                        size='large'
                                        onClick={handleReset}
                                        className='d-flex align-items-center'
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        type='primary'
                                        icon={<SaveOutlined />}
                                        size='large'
                                        onClick={handleSubmit}
                                        className='d-flex align-items-center'
                                        loading={loading}
                                    >
                                        Save
                                    </Button>
                                </Space>
                            </Row>
                        </div>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default AddDeliveryCharge;
