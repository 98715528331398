import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    DeleteFilled,
    EditFilled,
    PlusOutlined,
} from "@ant-design/icons";
import {
    Breadcrumb,
    Button,
    Col,
    Image,
    message,
    Modal,
    Popconfirm,
    Row,
    Table,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Topbar from "../../components/TopBar";
import PrivateAxios from "../../services/axiosService";
import { token } from "../../utils";
import { IMAGE_URL } from "../../utils/constants";
import AddVariant from "../../components/AddVariant";
import styles from "./ProductDetail.module.scss";

const ProductDetail = () => {
    const { productId } = useParams();
    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState([]);
    const [modalData, setModalData] = useState({
        open: false,
        title: "",
        data: "",
    });
    const {
        state: { data },
    } = useLocation();

    //FETCH ALL CATEGORIES
    const fetchProduct = useCallback(() => {
        setLoading(true);
        let config = {
            headers: {
                "x-auth-token": token(),
            },
        };
        PrivateAxios.get(`/products/${productId}`, config)
            .then(function (response) {
                setLoading(false);
                setProduct(response?.data?.variants);
            })
            .catch(function (error) {
                setLoading(false);
                message.error(error?.response?.data?.message);
            });
    }, [productId]);

    useEffect(() => {
        if (data?.variants?.length > 0) {
            fetchProduct();
        }
    }, [data?.variants?.length, fetchProduct]);

    //DELETE A PRODUCT FROM DB
    const deleteProductHandler = (id) => {
        setLoading(true);
        let config = {
            headers: {
                "x-auth-token": token(),
            },
        };

        PrivateAxios.delete(`/products/${id}`, config)
            .then(function (response) {
                setLoading(false);
                setProduct((prevState) =>
                    prevState.filter((variant) => variant._id !== id)
                );
                response && message.success("Product Deleted Successfully");
            })
            .catch(function (error) {
                setLoading(false);
                message.error("Something went wrong!");
            });
    };

    //COLUMN DETAILS FOR PRODUCTS TABLE
    const columns = [
        {
            title: "ID",
            dataIndex: "_id",
            key: "_id",
            render: (text) => <p className='text-center'>{text}</p>,

            align: "center",
        },
        {
            title: "Image",
            dataIndex: "image",
            key: "image",
            render: (src) => (
                <img
                    src={src}
                    alt='product-img'
                    className={styles.productImg}
                />
            ),

            align: "center",
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            align: "center",
        },
        {
            title: "Price",
            key: "price",
            render: (data) => (
                <span>{`AED ${Number(data?.price)?.toFixed(2)}`}</span>
            ),

            align: "center",
        },
        {
            title: "Visibility",
            key: "isActive",
            render: (data) => {
                return data.isActive ? (
                    <CheckCircleOutlined className='text-success fs-6' />
                ) : (
                    <CloseCircleOutlined className='text-danger fs-6' />
                );
            },
            align: "center",
        },
        {
            title: "Edit",
            key: "edit",
            align: "center",
            render: (item) => (
                <Button
                    type='text'
                    shape='circle'
                    icon={<EditFilled />}
                    onClick={(e) => {
                        e.stopPropagation();
                        setModalData({
                            open: true,
                            data: item,
                            title: "Edit Variant",
                        });
                    }}
                />
            ),
        },
        {
            title: "Delete",
            key: "delete",
            align: "center",
            render: (item) => (
                <Popconfirm
                    title='Are you sure to delete this variant?'
                    onConfirm={(e) => {
                        e.stopPropagation();
                        deleteProductHandler(item?._id);
                    }}
                    onCancel={(e) => {
                        e.stopPropagation();
                    }}
                    okText='Yes'
                    cancelText='No'
                >
                    <Button
                        type='text'
                        shape='circle'
                        icon={<DeleteFilled />}
                        className='text-danger'
                        onClick={(e) => e.stopPropagation()}
                    />
                </Popconfirm>
            ),
        },
    ];

    //DATASOURCE FOR PRODUCT TABLE
    const dataSource =
        product?.length > 0 &&
        product.map((item, i) => ({
            key: item?._id,
            id: i + 1,
            _id: item?._id,
            image: IMAGE_URL + item?.images?.mainImage,
            name: item?.name,
            category: item?.categories,
            price: Number(item?.price)?.toFixed(2),
            description: item?.description,
            isActive: item?.isActive,
            imageArr: item?.images?.gallery,
            ...item,
        }));

    const LeftComponent = () => (
        <div>
            <Breadcrumb separator='>'>
                <Breadcrumb.Item href='/products'>Catalog</Breadcrumb.Item>
                <Breadcrumb.Item href='/products'>Products</Breadcrumb.Item>
                <Breadcrumb.Item href='/products'>
                    Product Detail
                </Breadcrumb.Item>
            </Breadcrumb>
            <h3 className={styles.heading}> {data?.name}</h3>
        </div>
    );

    const RightComponent = () => <div />;

    return (
        <div className={styles.ProductDetailWrapper}>
            <Topbar
                LeftComponent={LeftComponent}
                RightComponent={RightComponent}
            />
            <div className={styles.ProductDetailContainer}>
                <div className={styles.content}>
                    <Row className='mb-3'>
                        <Col span={3}> Name :</Col>
                        <Col span={16}>{data.name}</Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col span={3}> Description :</Col>
                        <Col span={16}>{data.description}</Col>
                    </Row>
                    {data?.productType !== "variant" && (
                        <Row className='mb-3'>
                            <Col span={3}> Price :</Col>
                            <Col span={16}>
                                AED {Number(data.price).toFixed(2)}
                            </Col>
                        </Row>
                    )}
                    <Row className='mb-3'>
                        <Col span={3}> Category :</Col>
                        <Col span={16}>{data.category.name}</Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col span={3}> Visibility :</Col>
                        <Col span={16}>{data.isActive ? "True" : "False"}</Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col span={3}> Slug :</Col>
                        <Col span={16}>{data.slug}</Col>
                    </Row>
                    <Row className='mb-3' align='middle'>
                        <Col span={3}> Primary Image :</Col>
                        <Col span={16}>
                            <Image
                                className='border'
                                width={100}
                                height={100}
                                src={`${IMAGE_URL}${data?.images?.mainImage}`}
                            />
                        </Col>
                    </Row>
                    {data?.productType !== "variant" && (
                        <Row className='mb-3' align='middle'>
                            <Col span={3}> Gallery Images :</Col>
                            <Col span={16}>
                                <Row className='mb-3' gutter={10}>
                                    {data?.images?.gallery?.length > 0 &&
                                        data?.images?.gallery.map(
                                            (image, index) => (
                                                <Col key={index}>
                                                    <Image
                                                        className='border'
                                                        width={100}
                                                        height={100}
                                                        src={`${IMAGE_URL}${image}`}
                                                    />
                                                </Col>
                                            )
                                        )}
                                </Row>
                            </Col>
                        </Row>
                    )}
                    {data?.productType === "variant" && (
                        <>
                            <div className={styles.variantHeader}>
                                <p className={styles.variantTitle}>Variants</p>
                                <Button
                                    type='primary'
                                    icon={<PlusOutlined />}
                                    onClick={() =>
                                        setModalData({
                                            open: true,
                                            title: "Add Variant",
                                            data: null,
                                        })
                                    }
                                >
                                    Add Variant
                                </Button>
                            </div>
                            <Table
                                loading={loading}
                                columns={columns}
                                dataSource={dataSource}
                            />
                        </>
                    )}
                </div>
            </div>

            <Modal
                footer={null}
                open={modalData.open}
                onCancel={() =>
                    setModalData({ open: false, title: "", data: "" })
                }
                destroyOnClose={true}
                width={"80%"}
            >
                <AddVariant
                    setModalData={setModalData}
                    data={modalData.data}
                    productId={productId}
                    setProduct={setProduct}
                    title={modalData.title}
                />
            </Modal>
        </div>
    );
};

export default ProductDetail;
