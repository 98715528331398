// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TagsInputComponent_tagsInputContainer__1ufvI {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  flex-wrap: wrap;\n  min-height: 30px;\n  border-radius: 5px;\n}\n.TagsInputComponent_tagsInputContainer__1ufvI span[class*=ant-tag] {\n  background-color: var(--primary-color);\n  color: #ffffff;\n}\n.TagsInputComponent_tagsInputContainer__1ufvI input {\n  flex: 1 1;\n  outline: none !important;\n  border: none !important;\n}\n.TagsInputComponent_tagsInputContainer__1ufvI ::placeholder {\n  color: rgb(188, 188, 188);\n  opacity: 1; /* Firefox */\n}\n.TagsInputComponent_tagsInputContainer__1ufvI :-ms-input-placeholder {\n  color: rgb(188, 188, 188);\n}\n.TagsInputComponent_tagsInputContainer__1ufvI ::-ms-input-placeholder {\n  color: rgb(188, 188, 188);\n}", "",{"version":3,"sources":["webpack://./src/components/TagsInput/TagsInputComponent.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AACI;EACI,sCAAA;EACA,cAAA;AACR;AAEI;EACI,SAAA;EACA,wBAAA;EACA,uBAAA;AAAR;AAEI;EACI,yBAAA;EACA,UAAA,EAAA,YAAA;AAAR;AAEI;EACI,yBAAA;AAAR;AAGI;EACI,yBAAA;AADR","sourcesContent":[".tagsInputContainer {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    flex-wrap: wrap;\n    min-height: 30px;\n    border-radius: 5px;\n\n    span[class*=\"ant-tag\"] {\n        background-color: var(--primary-color);\n        color: #ffffff;\n    }\n\n    input {\n        flex: 1;\n        outline: none !important;\n        border: none !important;\n    }\n    ::placeholder {\n        color: rgb(188, 188, 188);\n        opacity: 1; /* Firefox */\n    }\n    :-ms-input-placeholder {\n        color: rgb(188, 188, 188);\n    }\n\n    ::-ms-input-placeholder {\n        color: rgb(188, 188, 188);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tagsInputContainer": "TagsInputComponent_tagsInputContainer__1ufvI"
};
export default ___CSS_LOADER_EXPORT___;
